import { useEffect } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { fetcher } from "@/Services/axios";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useUrlQuery } from "@/Utils/router";

type FormData = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export default function ResetPassword() {
  const query = useUrlQuery();
  const { token } = useParams();
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      token: token,
      email: query.get("email") || "",
      password: "",
      password_confirmation: "",
    },
  });

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      await fetcher.post("/reset-password", data);
      toast.success("Password reset successful!");
    } catch (error) {
      toast.error("Error resetting password!");
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputLabel htmlFor="email" value="Email" />

          <TextInput
            id="email"
            type="email"
            className="mt-1 block w-full"
            autoComplete="username"
            {...register("email", { required: "Email is required" })}
          />

          <InputError message={errors.email?.message} className="mt-2" />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            id="password"
            type="password"
            className="mt-1 block w-full"
            autoComplete="new-password"
            isFocused={true}
            {...register("password", { required: "Password is required" })}
          />

          <InputError message={errors.password?.message} className="mt-2" />
        </div>

        <div className="mt-4">
          <InputLabel
            htmlFor="password_confirmation"
            value="Confirm Password"
          />

          <TextInput
            type="password"
            className="mt-1 block w-full"
            autoComplete="new-password"
            {...register("password_confirmation", {
              required: "Password confirmation is required",
            })}
          />

          <InputError
            message={errors.password_confirmation?.message}
            className="mt-2"
          />
        </div>

        <div className="flex items-center justify-end mt-4">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
}
