import React, { createContext, useState } from "react";

interface ModalState {
  target: string;
  show: boolean;
}

interface ModalContextProps {
  isActive: (target: string) => boolean;
  add: (target: string) => void;
  show: (target: string) => void;
  close: (target: string) => void;
}

export const ModalContext = createContext<ModalContextProps>({
  isActive: () => false,
  add: () => {},
  show: () => {},
  close: () => {},
});

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [data, setData] = useState<ModalState[]>([]);

  const find = (target: string) => {
    return data.find((dataItem) => dataItem.target === target);
  };

  const add = (target: string) => {
    const item = find(target);
    if (!item) {
      setData((prevData) => [...prevData, { target, show: false }]);
    }
  };

  const show = (target: string) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.target === target ? { ...dataItem, show: true } : dataItem,
      ),
    );
  };

  const close = (target: string) => {
    setData((prevData) =>
      prevData.map((dataItem) =>
        dataItem.target === target ? { ...dataItem, show: false } : dataItem,
      ),
    );
  };

  const isActive = (target: string) => {
    const item = find(target);
    return item?.show || false;
  };

  const contextValue: ModalContextProps = {
    isActive,
    add,
    show,
    close,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};
