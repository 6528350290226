import { Dispatch } from "react";
import axios from "axios";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import { SubsectionDataType } from "@/Types/projects";
import { Archetype } from "@/Types/audience-archetype";
import {
  setKeyTouchPointData,
  setKeyTouchPointLoading,
} from "@/Context/actions/key_touch_points";
import { fetcher } from "@/Services/axios";

export const handleGenerateKeyTouchPoint =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    try {
      dispatch(
        setKeyTouchPointLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setKeyTouchPointLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/key-touch-point/generate`,
        {
          generate_more: regenerate_prompt,
        },
      );
      clearInterval(interval);
      dispatch(
        setKeyTouchPointLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setKeyTouchPointLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setKeyTouchPointData(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setKeyTouchPointLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleEditedKeyTouchPoint =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, history_id: number, content: string) => {
    try {
      const { data } = await fetcher.put(
        `/${project_slug}/history/${history_id}`,
        {
          content: content,
        },
      );

      dispatch(setKeyTouchPointData(data.data));
    } catch (err) {
      // TODO: dispatch error state
    }
  };

export const handleSetKeyTouchPointData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setKeyTouchPointData(data));
  };
