// TargetAudienceList.tsx
import React from "react";
import { TargetAudienceItem } from "@/Types/target_audience";
import MarkdownEditorReadOnly from "@/Components/MarkdownEditorReadOnly";

interface TargetAudienceListProps {
  items: TargetAudienceItem[];
}

const TargetAudienceList: React.FC<TargetAudienceListProps> = ({ items }) => {
  return (
    <ul className='list-disc ms-20'>
      {items.length > 0 &&
        items.map((item, index) => (
          <li key={index}>
            <MarkdownEditorReadOnly
              key={index}
              content={item.title + " " + item.content}
            />
          </li>
        ))}
    </ul>
  );
};

export default TargetAudienceList;
