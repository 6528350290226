import validator from "validator";

const validation = () => {
  const isEmail = (email: string) => {
    return validator.isEmail(email);
  };

  const isPassword = (password: string) => {
    return typeof password === "string";
  };

  const isWebsite = (website: string) => {
    const isValidURL = validator.isURL(website, {
      require_protocol: false,
    });

    const containsInvalidChars = /[{}]/.test(website);

    return isValidURL && !containsInvalidChars;
  };

  return {
    isEmail,
    isPassword,
    isWebsite,
  };
};

export default validation();
