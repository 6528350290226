const set = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

const get = (key: string) => {
  const value = window.sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

const remove = (key: string) => {
  window.sessionStorage.removeItem(key);
};

export const sessionStorage = {
  set,
  get,
  remove,
};
