import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

import TextInput from "./TextInput";

const EyeIcon = ({ show = false, handleShow = () => {} }) => {
  return (
    <div className="flex items-center justify-center mx-10 h-full text-black02 text-opacity-90 text-15 whitespace-nowrap">
      <div className="cursor-pointer" onClick={handleShow}>
        <Icon
          icon={show ? "mdi:eye-off" : "mdi:eye-outline"}
          className="w-19 h-w-19"
        />
      </div>
    </div>
  );
};

const PasswordInput = (props: any) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  return (
    <TextInput
      type={show ? "text" : "password"}
      rightElement={<EyeIcon show={show} handleShow={handleShow} />}
      {...props}
    />
  );
};

export default PasswordInput;
