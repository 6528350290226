import { fetcher } from "@/Services/axios";
import { useQuery } from "@tanstack/react-query";
import { KeyTouchPointResponse } from "@/Types/key_touch_point";

const getLists = async (
  projectSlug: string,
): Promise<KeyTouchPointResponse> => {
  try {
    const response = await fetcher.get(`${projectSlug}/key-touch-point/lists`);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "An error occurred");
  }
};

const useKeyTouchPoint = (projectSlug: string) => {
  return useQuery<KeyTouchPointResponse, Error>({
    queryKey: ["keyTouchPoint", projectSlug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getLists(projectSlug),
    refetchOnWindowFocus: false,
  });
};

export default useKeyTouchPoint;
