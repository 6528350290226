import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { User } from "@/Types";

interface ResponseUsers {
  users: User[];
}

const fetchApprovedUsers = async (): Promise<ResponseUsers> => {
  try {
    const response = await fetcher.post("/projects/approved-users", {});
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch approved users",
    );
  }
};

const useApprovedUsers = () => {
  return useQuery<ResponseUsers, Error>({
    queryKey: ["approvedUsers"],
    queryFn: fetchApprovedUsers,
    refetchOnMount: false,
  });
};

export default useApprovedUsers;
