import { forwardRef, useContext, useState } from "react";
import { Icon } from "@iconify/react";

import TextInput from "@/Components/TextInput";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import { ProjectContext } from "@/Context/ProjectContext";
import RegenerateModal from "./RegenerateModal";

import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { SubmissionFormPayload } from "@/Types/form";

interface ProductInformationProps {
  data: {
    product_name: string;
    brand_name: string;
    product_links: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isLocked?: boolean;
  isEditor: boolean;
  isFetching: boolean;
}

const ProductInformation = forwardRef<HTMLDivElement, ProductInformationProps>(
  (props, ref) => {
    const {
      data,
      setData,
      errors,
      setError,
      isActive,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
    } = props;
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(0);
      if (!isNotError) {
        setIsEditing(true);
        return;
      }
      setIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`flex flex-col w-full mb-24 ${
          isActive ? "" : "cursor-not-allowed opacity-30"
        }`}
      >
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-24 leading-32 mb-15">
            Product Information
          </h1>
          {!isFetching &&
            state.submission.is_product_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <Icon
                icon="lucide:pencil-line"
                className="text-blue-redx w-16 h-16 cursor-pointer"
                onClick={() => setIsEditing(true)}
              />
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div
              className="inline-flex items-center gap-x-4 cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              <p className="text-14 font-semibold text-blue-redx">Save</p>
              <Icon icon="lucide:save" className="text-blue-redx w-16 h-16" />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 w-full gap-24">
          <div>
            <InputLabel
              htmlFor="product_name"
              value="Product Name"
              className="!text-black-redx"
            />

            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                id="product_name"
                type="text"
                name="product_name"
                value={data.product_name}
                error={errors.product_name?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input your product name here"
                autoComplete="product_name"
                disabled={!isActive}
                isFocused={false}
                onChange={(e) => {
                  setData("product_name", e.target.value);
                  setError("product_name", { message: "" });
                }}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.product_name.length > 0 ? data.product_name : "-"}
              </p>
            )}

            <InputError
              message={errors.product_name?.message}
              className="mt-2"
            />
          </div>
          <div>
            <InputLabel
              htmlFor="brand_name"
              value="Brand Name"
              className="!text-black-redx"
            />
            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                id="brand_name"
                type="text"
                name="brand_name"
                value={data.brand_name}
                error={errors.brand_name?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input your brand name here"
                autoComplete="brand_name"
                disabled={!isActive}
                isFocused={false}
                onChange={(e) => {
                  setData("brand_name", e.target.value);
                  setError("brand_name", { message: "" });
                }}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.brand_name.length > 0 ? data.brand_name : "-"}
              </p>
            )}

            <InputError message={errors.brand_name?.message} className="mt-2" />
          </div>
          <div>
            <InputLabel
              htmlFor="product_links"
              value="Product Related Links (Optional)"
              className="!text-black-redx"
            />
            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_product_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextInput
                id="product_links"
                type="text"
                name="product_links"
                value={data.product_links}
                error={errors.product_links?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input your product related links here"
                autoComplete="product_links"
                disabled={!isActive}
                isFocused={false}
                onChange={(e) => setData("product_links", e.target.value)}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.product_links.length > 0 ? data.product_links : "-"}
              </p>
            )}

            <InputError
              message={errors.product_links?.message}
              className="mt-2"
            />
          </div>
        </div>
        <RegenerateModal
          isOpen={isModalOpen}
          onClose={() => {
            fetchData();
            setIsEditing(false);
            setIsModalOpen(false);
          }}
          handleSubmit={submitForm}
        />
      </div>
    );
  },
);

export default ProductInformation;
