import {
  Popover,
  PopoverButton,
  Transition,
  PopoverPanel,
} from "@headlessui/react";
import ResponsiveNavLink from "@/Components/ResponsiveNavLink";
import { Icon } from "@iconify/react/dist/iconify.js";

const Profile: React.FC<{ avatar: string }> = ({ avatar }) => {
  return (
    <Popover>
      <PopoverButton className="bg-white p-3 flex items-center rounded-full cursor-pointer">
        <div className="w-30 h-30 rounded-full overflow-hidden">
          {avatar && <img src={avatar} alt="User avatar" />}
        </div>
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="z-60 absolute right-20 divide-y rounded-lg bg-white text-14 font-semibold shadow-lg p-10 [--anchor-offset:5px]">
          <ResponsiveNavLink
            to="/logout"
            className="min-w-200 inline-flex items-center justify-between py-10 px-5 !hover:bg-transparent cursor-pointer"
          >
            <span>Log Out</span>
            <Icon
              icon="material-symbols:logout-rounded"
              className="w-16 h-16"
            />
          </ResponsiveNavLink>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default Profile;
