import React, { Fragment } from "react";

import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import { Icon } from "@iconify/react";

interface ModalProps {
  isOpen: boolean;
  hasButtonClose?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  hasButtonClose = true,
}) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClick={handleStopPropagation}
        as="div"
        className="fixed inset-0 z-60 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur" />
          </TransitionChild>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block md:max-w-xs p-0 my-8 relative overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {hasButtonClose && (
                <div
                  className="absolute z-30 top-20 right-20 text-black cursor-pointer"
                  onClick={onClose}
                >
                  <Icon icon="mdi:close" className="w-20 h-20" />
                </div>
              )}
              {children}
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
