import ProjectMembers from "@/Components/ProjectMembers";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect } from "react";
import useUser from "@/Hooks/react-query/useUser";
import useApprovedUsers from "@/Hooks/react-query/useApprovedUsers";

const ProjectHead: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const { data: user } = useUser();
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const navigate = useNavigate();
  const { Badge } = useRolesBadge(project, user);
  const { data, isLoading } = useApprovedUsers();

  useEffect(() => {
    if (data) {
      setInitialUsers(data.users);
    }
  }, [data]);

  return (
    <div className="flex flex-col w-full pb-24 border-b-2 border-gray-200">
      <div className="flex w-full items-center text-32 font-bold mb-10">
        <h1>{project.name}</h1>
      </div>
      {!isLoading && (
        <div className="flex items-center w-full gap-x-30">
          <ProjectMembers project={project} initialUsers={initialUsers} />
          <div className="inline-flex gap-x-8 text-grey-redx">
            <Icon icon="uil:clock" className="w-24 h-24" />
            <p>{moment(project.updated_at).format("DD MMMM YYYY - HH:mm")}</p>
          </div>
          <Badge />
        </div>
      )}
    </div>
  );
};

export default ProjectHead;
