import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { ProjectProps } from "@/Types/projects";

interface ResponseProjects {
  projects: ProjectProps[];
}

const fetchProjects = async (): Promise<ResponseProjects> => {
  try {
    // TODO: replace with pagination and react-query's fetcher
    const response = await fetcher.get("/projects?page=1&limit=100");
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch projects data",
    );
  }
};

const useProjects = () => {
  return useQuery<any, Error>({
    queryKey: ["projects"],
    queryFn: fetchProjects,
  });
};

export default useProjects;
