import { User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import RoleBadge from "@/Components/RoleBadge";
import _ from "lodash";

const useRolesBadge = (project: ProjectProps, user: User) => {
  const roles: string[] = [];

  if (!_.isEmpty(project) && !_.isEmpty(user)) {
    if (project.owner.id === user?.id) {
      roles.push("Owner");
    } else {
      project.roles.map((role) => {
        if (role.pivot.user_id === user?.id) {
          roles.push(role.name);
        }
      });
    }
  }

  const Badge = () => <RoleBadge role={roles.join(", ")} />;

  return {
    roles,
    Badge,
  };
};

export default useRolesBadge;
