import { PropsWithChildren } from "react";
import { Toaster } from "react-hot-toast";

import { SidebarProvider, useSidebarContext } from "@/Context/SidebarContext";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import Sidebar from "@/Components/TopMenu/Sidebar";
import ProjectHead from "@/Components/TopMenu/ProjectHead";
import ProjectFooter from "@/Components/TopMenu/ProjectFooter";
import { ProjectProvider } from "@/Context/ProjectContext";
import { useNavigate, useParams } from "react-router-dom";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";
import { useEffect } from "react";
import _ from "lodash";

const ProjectLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { isSidebarOpen } = useSidebarContext();
  const params = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useProjectDetail(
    params?.project_slug ?? "",
  );

  useEffect(() => {
    if (!isLoading && _.isEmpty(project)) {
      navigate("/404");
    }
  }, [project, isLoading]);

  useEffect(() => {
    // TODO: Will replace with openreplay
    (function (c: any, l: Document, a: string, r: string, i: string, t?: HTMLScriptElement, y?: HTMLElement) {
      c[a] = c[a] || function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
      t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0] as HTMLElement;

      y?.parentNode?.insertBefore(t, y);
    })(window, document, 'clarity', 'script', import.meta.env.VITE_CLARITY_PROJECT_CODE);  }, []);

  return (
    <ProjectProvider>
      <SidebarProvider>
        <AuthenticatedLayout>
          {!isLoading && project && !_.isEmpty(project) && (
            <>
              <Sidebar project={project} />
              <div className='max-h-full h-full ml-0 lg:pl-[25%] w-full'>
                <div className='px-20 pb-40 pt-73 z-10  w-full'>
                  <ProjectHead project={project} />
                  <div className='pb-80 lg:pb-0'> {children}</div>
                  <ProjectFooter project={project} />
                </div>
              </div>
            </>
          )}
        </AuthenticatedLayout>
        <Toaster
          position='bottom-center'
          containerStyle={{
            bottom: 120,
          }}
          toastOptions={{
            duration: 5000,
          }}
        />
      </SidebarProvider>
    </ProjectProvider>
  );
};

export default ProjectLayout;
