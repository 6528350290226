import GenerateLoading from "@/Components/GenerateLoading";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SourcesList from "@/Components/Projects/SourcesList";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { ProjectProps, SourcesType } from "@/Types/projects";
import {
  TargetAudienceItem,
  MediaConsumptionPatternsProps,
} from "@/Types/target_audience";
import { isYourRolesCanEdit } from "@/Utils/helper";
import { Icon } from "@iconify/react/dist/iconify.js";
import React, { Fragment, useContext, useEffect, useState } from "react";
import MarkdownEditor from "@/Components/MarkdownEditor";
import { ProjectContext } from "@/Context/ProjectContext";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { jsonToMarkdown, markdownToJson } from "../Utils/utils";
import TargetAudienceList from "./TargetAudienceList";
import { progressPortion } from "@/Utils/dispatcher";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import { useForm } from "react-hook-form";
import {
  AudienceMediaConsumption,
  AudienceMediaConsumptionSchema,
} from "@/Types/target_audience/schema-audience-media-consumption-pattern";
import { zodResolver } from "@hookform/resolvers/zod";
import useUpdateTargetAudience from "@/Hooks/react-query/target-audience/useUpdateTargetAudience";
import { SectionList } from "@/Types/tabs";

interface Props {
  project: ProjectProps;
  section: SectionList;
  isEditing: boolean;
  isLoading: boolean;
  isGenerateMediaConsumptionPatterns: boolean;
  toggleIsEditing: () => void;
}

const CardMediaConsumptionPatterns: React.FC<Props> = ({
  project,
  section,
  isEditing,
  isLoading,
  isGenerateMediaConsumptionPatterns,
  toggleIsEditing,
}) => {
  const [isGenerate, setIsGenerate] = useState(false);
  const [state, dispatch] = useContext(ProjectContext);
  const maxGenerateLimit = project.max_generated_data;
  const { data: user } = useUser();
  const { roles } = useRolesBadge(project, user);
  const { mutate: updateTargetAudience } = useUpdateTargetAudience();
  const [parsedData, setParsedData] =
    useState<MediaConsumptionPatternsProps | null>({
      media_consumption_pattern: [],
    });
  const [mediaConsumptionPatterns, setMediaConsumptionPatterns] = useState<
    TargetAudienceItem[]
  >([]);

  const {
    handleGenerateTargetAudienceMediaConsumptionPatterns,
    handleSetTargetAudienceMediaConsumptionPatterns,
  } = useGenerate(dispatch);

  const stateProps = state.targetAudience.media_consumption_patterns;
  const status = stateProps.status;
  const progress = stateProps.progress;
  const data = stateProps.data;
  const content = data.content;
  const sources = data.sources;
  const total_regenerate: number = data.total_regenerate
    ? data.total_regenerate
    : 0;

  const { reset, setValue, getValues } =
    useForm<AudienceMediaConsumptionSchema>({
      resolver: zodResolver(AudienceMediaConsumption),
      defaultValues: {
        media_consumption_patterns: "",
      },
      mode: "all",
    });

  const { isTargetAudienceMediaConsumptionPattern: isTargetAudience } =
    useHistoryStatus(project);

  const handleSetParsedData = () => {
    const stringContent = content;
    if (stringContent) {
      try {
        const parsedContent = JSON.parse(stringContent);

        reset({
          media_consumption_patterns: jsonToMarkdown(
            parsedContent.media_consumption_pattern,
          ),
        });

        setParsedData(parsedContent);
        setMediaConsumptionPatterns(parsedContent.media_consumption_pattern);
      } catch (error) {
        console.error(error, "error media consumption patterns");
        console.log(
          "error while parsing =>",
          stringContent,
          "media consumption patterns",
        );
      }
    }
  };

  const handleSaveData = () => {
    toggleIsEditing();

    const formattedContent: MediaConsumptionPatternsProps = {
      media_consumption_pattern: markdownToJson(
        getValues("media_consumption_patterns"),
      ),
    };

    updateTargetAudience(
      {
        payload: JSON.stringify(formattedContent),
        historyId: data.history_id,
        projectSlug: project.slug,
      },
      {
        onSuccess: (data) => {
          handleSetTargetAudienceMediaConsumptionPatterns()(data.data);
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    handleGenerateTargetAudienceMediaConsumptionPatterns()(
      project.slug,
      prompt,
    );
  };

  useEffect(() => {
    handleSetParsedData();
  }, [data]);

  useEffect(() => {
    if (
      !isTargetAudience.isQuerying &&
      parsedData?.media_consumption_pattern.length
    ) {
      setIsGenerate(false);
    }
  }, [isTargetAudience.isQuerying, parsedData]);

  useEffect(() => {
    if (isGenerateMediaConsumptionPatterns) {
      setIsGenerate(true);
    }
  }, [isGenerateMediaConsumptionPatterns]);

  if (isGenerate) {
    return (
      <GenerateLoading
        progress={progressPortion({
          progress: Number((progress / 2).toFixed(0)),
          isQuerying: isTargetAudience.isQuerying,
          isContentAvailable:
            parsedData?.media_consumption_pattern.length !== 0,
        })}
        project={project}
        section={section}
      />
    );
  }

  return (
    <Fragment>
      {/* content */}
      <div className="flex flex-col gap-24 text-black-redx">
        {parsedData && (
          <Fragment>
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <h3 className="font-bold text-16 leading-19">
                  Media Consumption Patterns
                </h3>
                {isYourRolesCanEdit(roles) && !isEditing && (
                  <RegenerateButton
                    onSubmit={(formData) =>
                      handleRegenerateButton(formData.prompt)
                    }
                    limit={total_regenerate}
                    maxLimit={maxGenerateLimit}
                  />
                )}
              </div>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(mediaConsumptionPatterns)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue(
                      "media_consumption_patterns",
                      jsonToMarkdown(json),
                    );
                    setMediaConsumptionPatterns(json);
                  }}
                />
              ) : (
                <>
                  { isLoading ? (
                      <div
                        className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ) : (
                      <TargetAudienceList items={mediaConsumptionPatterns} />
                    )
                  }
                </>
              )}
            </div>
          </Fragment>
        )}
      </div>

      <div className="flex w-full justify-end items-center my-24 gap-x-15">
        {isYourRolesCanEdit(roles) &&
          (isEditing ? (
            <Fragment>
              <button
                className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
                onClick={() => {
                  handleSetParsedData();
                  toggleIsEditing();
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon icon="lucide:x" className="w-20 h-20" />
              </button>
              <button
                className="inline-flex items-center gap-x-4 text-blue-redx cursor-pointer"
                onClick={handleSaveData}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon icon="lucide:save" className="w-20 h-20" />
              </button>
            </Fragment>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>
      <div className='flex w-full pb-60 mr-1'>
        {!isLoading && sources && (
          <SourcesList sources={sources as SourcesType[]} />
        )}
        {isLoading && (
          <div className='inline-flex gap-10'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CardMediaConsumptionPatterns;
