import React, { useEffect, Fragment, useContext, useState } from "react";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { Icon } from "@iconify/react";

import { ProjectProps } from "@/Types/projects";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveRouteNext,
  saveRoutePrev,
  saveInformation,
} from "@/Context/actions/projectActions";

import GenerateLoading from "@/Components/GenerateLoading";
import SourcesList from "@/Components/Projects/SourcesList";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import MarkdownEditor from "@/Components/MarkdownEditor";
import { historiesFilter } from "@/Helpers/filter";
import { progressPortion } from "@/Utils/dispatcher";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import useUser from "@/Hooks/react-query/useUser";
import { useNavigate } from "react-router-dom";
import useHistoryLatest from "@/Hooks/react-query/useHistoryLatest";
import { fetcher } from "@/Services/axios";
import { SectionList } from "@/Types/tabs";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";
import MarkdownEditorReadOnly from "@/Components/MarkdownEditorReadOnly";

const ProductResearchIndex: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const maxGenerateLimit = project.max_generated_data;
  const { data: user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [editedData, setEditedData] = useState("");
  const [isGenerateProductDescription, setIsGenerateProductDescription] =
    useState(false);
  const [isGenerateResearchMainFeatures, setIsGenerateResearchMainFeatures] =
    useState(false);
  const [
    isGenerateResearchMarcommHighlights,
    setIsGenerateResearchMarcommHighlights,
  ] = useState(false);
  const { roles } = useRolesBadge(project, user);
  const navigate = useNavigate();
  const {
    handleSetProductResearchProductDescriptionData,
    handleSetProductResearchMainFeaturesData,
    handleSetProductResearchMarcommHighlightsData,
    handleGenerateProductResearchProductDescription,
    handleGenerateProductResearchMainFeatures,
    handleGenerateProductResearchMarcommHighlights,
  } = useGenerate(dispatch);
  const {
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isPending: isPendingProductResearch,
  } = useHistoryStatus(project);
  const { data, isLoading } = useHistoryLatest(project);

  const isEditor = roles.includes("Owner") || roles.includes("Strategist");

  const sectionList: SectionList[] = [
    {
      title: "Product Description",
      value: "product_description",
      section: "product_research",
    },
    {
      title: "Main Features",
      value: "main_features",
      section: "product_research",
    },
    {
      title: "Marcomm Highlights",
      value: "marcomm_highlights",
      section: "product_research",
    },
  ];
  const totalRegenerate: { [key: string]: number } = {
    product_description:
      state.productResearch.product_description.data.total_regenerate ?? 0,
    main_features:
      state.productResearch.main_features.data.total_regenerate ?? 0,
    marcomm_highlights:
      state.productResearch.marcomm_highlights.data.total_regenerate ?? 0,
  };

  const handleRegenerateButton =
    (section: string) => async (data: { prompt: string }) => {
      if (section === "product_description") {
        await handleGenerateProductResearchProductDescription()(
          project.slug,
          data.prompt,
        );
        setIsGenerateProductDescription(true);
      }

      if (section === "main_features") {
        await handleGenerateProductResearchMainFeatures()(
          project.slug,
          data.prompt,
        );
        setIsGenerateResearchMainFeatures(true);
      }

      if (section === "marcomm_highlights") {
        await handleGenerateProductResearchMarcommHighlights()(
          project.slug,
          data.prompt,
        );
        setIsGenerateResearchMainFeatures(true);
      }
    };

  const toggleIsEditing = (content: string) => {
    setEditedData(content);
    setIsEditing(!isEditing);
  };

  const handleCancelEdit = () => {
    setEditedData("");
    setIsEditing(!isEditing);
  };

  const handleSubmitEdit = (subsection: string) => async () => {
    const historyId =
      state.productResearch[subsection as keyof typeof state.productResearch]
        .data.history_id;

    const { data } = await fetcher.put(
      `/${project.slug}/history/${historyId}`,
      { content: editedData },
    );

    if (subsection === "product_description")
      handleSetProductResearchProductDescriptionData()(data.data);
    if (subsection === "main_features")
      handleSetProductResearchMainFeaturesData()(data.data);
    if (subsection === "marcomm_highlights")
      handleSetProductResearchMarcommHighlightsData()(data.data);
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    const productResearchDescription = historiesFilter({
      data,
      section: "product_research",
      subSection: "product_description",
    });
    const productResearchMainFeatures = historiesFilter({
      data,
      section: "product_research",
      subSection: "main_features",
    });
    const productResearchMarcommHighlights = historiesFilter({
      data,
      section: "product_research",
      subSection: "marcomm_highlights",
    });

    if (productResearchDescription?.history_id) {
      handleSetProductResearchProductDescriptionData()(
        productResearchDescription,
      );
    } else {
      if (
        !isLoading &&
        !isPendingProductResearch &&
        !isProductResearchProductDescription.status
      ) {
        setIsGenerateProductDescription(true);
        !isProductResearchProductDescription.isQuerying &&
          handleGenerateProductResearchProductDescription()(project.slug);
      }
    }

    if (productResearchMainFeatures?.history_id) {
      handleSetProductResearchMainFeaturesData()(productResearchMainFeatures);
    } else {
      if (
        !isLoading &&
        !isPendingProductResearch &&
        !isProductResearchMainFeature.status
      ) {
        setIsGenerateResearchMainFeatures(true);

        !isProductResearchMainFeature.isQuerying &&
          handleGenerateProductResearchMainFeatures()(project.slug);
      }
    }

    if (productResearchMarcommHighlights?.history_id) {
      handleSetProductResearchMarcommHighlightsData()(
        productResearchMarcommHighlights,
      );
    } else {
      if (
        !isLoading &&
        !isPendingProductResearch &&
        !isProductResearchMarcommHighlight.status
      ) {
        setIsGenerateResearchMarcommHighlights(true);
        !isProductResearchMarcommHighlight.isQuerying &&
          handleGenerateProductResearchMarcommHighlights()(project.slug);
      }
    }
  }, [
    data,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isPendingProductResearch,
  ]);

  useEffect(() => {
    dispatch(
      saveRouteNext({
        label: "Next to Market Research",
        isActive: true,
        isDisabled: !state.submission.is_market_information_complete,
        onClick: () => navigate(`/${project.slug}/market-research`),
      }),
    );
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => navigate(`/${project.slug}/submission`),
      }),
    );
    dispatch(saveInformation(""));
  }, []);

  useEffect(() => {
    if (
      !isPendingProductResearch &&
      !isProductResearchProductDescription.isQuerying &&
      isProductResearchProductDescription.status
    ) {
      setIsGenerateProductDescription(false);
    }
    if (isProductResearchProductDescription.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      !isPendingProductResearch &&
      !isProductResearchMainFeature.isQuerying &&
      isProductResearchMainFeature.status
    ) {
      setIsGenerateResearchMainFeatures(false);
    }

    if (isProductResearchMainFeature.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }
    if (
      !isPendingProductResearch &&
      !isProductResearchMarcommHighlight.isQuerying &&
      isProductResearchMarcommHighlight.status
    ) {
      setIsGenerateResearchMarcommHighlights(false);
    }
    if (isProductResearchMarcommHighlight.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (
      !isPendingProductResearch &&
      (isProductResearchMainFeature.isQuerying ||
        isProductResearchProductDescription.isQuerying ||
        isProductResearchMarcommHighlight.isQuerying)
    ) {
      isProductResearchMainFeature.isQuerying &&
        setIsGenerateResearchMainFeatures(true);
      isProductResearchMarcommHighlight.isQuerying &&
        setIsGenerateResearchMarcommHighlights(true);
      isProductResearchProductDescription.isQuerying &&
        setIsGenerateProductDescription(true);
    }
  }, [
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    state.productResearch,
  ]);

  return (
    <Fragment>
      <div className='w-full h-full'>
        <div className='w-full sticky top-56 z-20 bg-white py-16'>
          <h1 className='text-25 font-bold mb-8 leading-none'>
            Product Research
          </h1>
          <span className='text-15 font-normal text-grey-redx'>
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup className='pb-80'>
          <TabList className={"flex w-full"}>
            {sectionList.map((section, index) => (
              <Tab
                disabled={isEditing}
                className='text-15 px-45 pb-10 border-b-1 text-black-redx w-1/3 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                key={index}>
                {section.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels className={"py-24"}>
            {sectionList.map((section, index) => {
              return (
                <TabPanel key={index}>
                  {(section.value === "product_description" &&
                    isGenerateProductDescription) ||
                  (section.value === "main_features" &&
                    isGenerateResearchMainFeatures) ||
                  (section.value === "marcomm_highlights" &&
                    isGenerateResearchMarcommHighlights) ||
                  !state.productResearch[
                    section.value as keyof typeof state.productResearch
                  ]?.data.content ? (
                    <GenerateLoading
                      progress={progressPortion({
                        progress: Number(
                          (
                            state.productResearch[
                              section.value as keyof typeof state.productResearch
                            ].progress / 2
                          ).toFixed(0),
                        ),
                        isQuerying:
                          section.value === "product_description"
                            ? isProductResearchProductDescription.isQuerying
                            : section.value === "main_features"
                            ? isProductResearchMainFeature.isQuerying
                            : section.value === "marcomm_highlights"
                            ? isProductResearchMarcommHighlight.isQuerying
                            : false,
                        isContentAvailable:
                          state.productResearch[
                            section.value as keyof typeof state.productResearch
                          ]?.data?.content !== "",
                      })}
                      project={project}
                      section={section}
                    />
                  ) : (
                    <Fragment>
                      <div className='flex w-full justify-between items-center mb-8'>
                        <h1 className='text-15 font-semibold'>
                          {section.title}
                        </h1>
                        {isEditor && !isEditing && (
                          <RegenerateButton
                            limit={totalRegenerate[section.value]}
                            maxLimit={maxGenerateLimit}
                            onSubmit={handleRegenerateButton(section.value)}
                          />
                        )}
                      </div>
                      {isEditing ? (
                        <div className='mb-12'>
                          <MarkdownEditor
                            content={
                              state.productResearch[
                                section.value as keyof typeof state.productResearch
                              ].data.content ?? ""
                            }
                            onChange={(html) => {
                              setEditedData(html);
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          {!isLoading &&
                            state.productResearch[
                              section.value as keyof typeof state.productResearch
                            ].data !== null && (
                              <MarkdownEditorReadOnly
                                content={
                                  state.productResearch[
                                    section.value as keyof typeof state.productResearch
                                  ].data.content
                                }
                              />
                            )}
                          {isLoading && (
                            <div className='w-full h-155 px-15 py-12 my-20 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx' />
                          )}
                        </>
                      )}
                      <div className='flex w-full justify-end items-center mb-24 gap-x-15'>
                        {isEditor &&
                          (isEditing ? (
                            <Fragment>
                              <button
                                className='inline-flex items-center gap-x-4 text-error-redx cursor-pointer'
                                onClick={handleCancelEdit}>
                                <span className='text-15 font-semibold'>
                                  Cancel
                                </span>
                                <Icon
                                  icon='lucide:x'
                                  className='w-20 h-20'
                                />
                              </button>
                              <button
                                className='inline-flex items-center gap-x-4 text-blue-redx cursor-pointer'
                                onClick={handleSubmitEdit(section.value)}>
                                <span className='text-15 font-semibold'>
                                  Save
                                </span>
                                <Icon
                                  icon='lucide:save'
                                  className='w-20 h-20'
                                />
                              </button>
                            </Fragment>
                          ) : (
                            <EditButton
                              toggleEditing={() =>
                                toggleIsEditing(
                                  state.productResearch[
                                    section.value as keyof typeof state.productResearch
                                  ].data.content,
                                )
                              }
                            />
                          ))}
                      </div>
                      <div className='flex w-full pb-60 mr-1'>
                        {!isLoading &&
                          state.productResearch[
                            section.value as keyof typeof state.productResearch
                          ].data.sources && (
                            <SourcesList
                              sources={
                                state.productResearch[
                                  section.value as keyof typeof state.productResearch
                                ].data.sources
                              }
                            />
                          )}
                        {isLoading && (
                          <div className='inline-flex gap-10'>
                            {Array.from({ length: 3 }).map((_, index) => (
                              <div
                                key={index}
                                className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </TabGroup>
      </div>
    </Fragment>
  );
};

export default ProductResearchIndex;
