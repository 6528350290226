import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { ProjectProps } from "@/Types/projects";
import { useState, useEffect } from "react";
import { HistoryStatus, HistoryStatusResponse } from "@/Types/history";
import toast from "react-hot-toast";

const useHistoryStatus = (project: ProjectProps, isEnabled: boolean = true) => {
  const defaultState: HistoryStatus = {
    status: false,
    isQuerying: true,
    isError: false,
  };

  // Function to fetch the latest status
  const fetchLatestStatus = async (): Promise<any> => {
    const url = `/${project.slug}/history/status/latest`; // Update your API endpoint accordingly
    const response = await fetcher.get(url);
    if (!response) throw new Error("Network response was not ok");
    return response.data;
  };

  const {
    data: response,
    isLoading,
    isError,
    error,
    isFetching,
    isPending,
  } = useQuery<HistoryStatusResponse>({
    queryKey: ["latestHistoryStatus", project.slug],
    queryFn: fetchLatestStatus, // directly pass the function reference here
    refetchInterval: 6000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    enabled: isEnabled ?? true,
  });

  // State hooks to manage the statuses
  const [
    isProductResearchProductDescription,
    setIsProductResearchProductDescription,
  ] = useState(defaultState);
  const [isProductResearchMainFeature, setIsProductResearchMainFeature] =
    useState(defaultState);

  const [
    isProductResearchMarcommHighlight,
    setIsProductResearchMarcommHighlight,
  ] = useState(defaultState);
  const [isMarketResearchDemand, setIsMarketResearchDemand] =
    useState(defaultState);
  const [isMarketResearchTrend, setIsMarketResearchTrend] =
    useState(defaultState);

  const [isTargetAudienceAudienceInsight, setIsTargetAudienceAudienceInsight] =
    useState(defaultState);
  const [isTargetAudienceBehavioralTrend, setIsTargetAudienceBehavioralTrend] =
    useState(defaultState);
  const [
    isTargetAudienceMediaConsumptionPattern,
    setIsTargetAudienceMediaConsumptionPattern,
  ] = useState(defaultState);
  const [
    isTargetAudiencePerceptionAnalysis,
    setIsTargetAudiencePerceptionAnalysis,
  ] = useState(defaultState);

  const [isKeyTouchPoint, setIsKeyTouchPoint] = useState(defaultState);
  const [isOnePageStrategy, setIsOnePageStrategy] = useState(defaultState);
  const [isKeyInsightsComplete, setIsKeyInsightsComplete] =
    useState(defaultState);
  const [isChallengeAndTaskOfTheBrand, setIsChallengeAndTaskOfTheBrand] =
    useState(defaultState);
  const [isAudienceArchetypeComplete, setIsAudienceArchetypeComplete] =
    useState(defaultState);
  const [isCompetitorAnalysisComplete, setIsCompetitorAnalysisComplete] =
    useState(defaultState);
  const [
    isOpportunityAmongCompetitionComplete,
    setIsOpportunityAmongCompetitionComplete,
  ] = useState(defaultState);
  const [totalCompetitors, setTotalCompetitors] = useState(0);

  // Effect to update state when the response changes
  useEffect(() => {
    if (response) {
      const { data } = response;

      setIsProductResearchProductDescription(
        data.product_research_product_description || defaultState,
      );
      setIsProductResearchMainFeature(
        data.product_research_main_features || defaultState,
      );
      setIsProductResearchMarcommHighlight(
        data.product_research_marcomm_highlight || defaultState,
      );

      setIsMarketResearchDemand(data.market_research_demand || defaultState);
      setIsMarketResearchTrend(data.market_research_trend || defaultState);

      setIsTargetAudienceAudienceInsight(
        data.target_audience_audience_insight || defaultState,
      );
      setIsTargetAudienceBehavioralTrend(
        data.target_audience_behavioral_trend || defaultState,
      );
      setIsTargetAudienceMediaConsumptionPattern(
        data.target_audience_media_consumption_pattern || defaultState,
      );
      setIsTargetAudiencePerceptionAnalysis(
        data.target_audience_media_perception_analysis || defaultState,
      );

      setIsKeyTouchPoint(data.key_touch_point || defaultState);
      setIsOnePageStrategy(data.one_page_strategy || defaultState);
      setIsKeyInsightsComplete(data.key_insights || defaultState);
      setIsCompetitorAnalysisComplete(data.competitor_analysis || defaultState);
      setIsOpportunityAmongCompetitionComplete(
        data.opportunity_among_competition || defaultState,
      );
      setIsAudienceArchetypeComplete(data.audience_archetype || defaultState);
      setIsChallengeAndTaskOfTheBrand(data.challenge_and_task || defaultState);
      setTotalCompetitors(data.total_competitor);
    }
  }, [response]);

  return {
    isFetching,
    isLoading,
    isPending,
    isError,
    error,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isMarketResearchDemand,
    isMarketResearchTrend,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isKeyTouchPoint,
    isOnePageStrategy,
    totalCompetitors,
  };
};

export default useHistoryStatus;
