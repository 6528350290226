import useClickOutside from "@/Hooks/useClickOutside";
import useTag from "@/Hooks/useTag";
import { User } from "@/Types";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  InputHTMLAttributes,
  useState,
  useRef,
} from "react";
import { isValidEmail } from "@/Utils/helper";

interface iTag {
  isFocused?: boolean;
  containerClassName?: string;
  error?: string;
  initialTags?: string[];
  onChangeTags?: (tags: string[]) => void;
  initialUsers: User[];
  exludedUsers?: User[];
  isClearTags?: boolean;
  onClearTagsComplete?: () => void;
}

export default forwardRef(function TextInputTags(
  {
    type = "text",
    className = "",
    containerClassName = "",
    isFocused = false,
    placeholder = "",
    error = "",
    initialTags = [],
    initialUsers = [],
    exludedUsers = [],
    isClearTags = false,
    onChangeTags = () => {},
    onClearTagsComplete = () => {},
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & iTag,
  ref,
) {
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [users, setUsers] = useState<User[]>(initialUsers);
  const [approvedUsers, setApprovedUsers] = useState<User[]>([]);
  const [whitelistEmails, setWhitelistEmails] = useState<string[]>([]);
  const elementRef = useRef<HTMLDivElement>(null);
  const tagUlRef = useRef<HTMLUListElement>(null);

  const { tags, tagInput, removeTag, inputKeyDown, addTag, clearTags } =
    useTag(initialTags);

  useEffect(() => {
    // set the approvedUsers state where email is not in the exludedUsers
    setUsers(
      initialUsers.filter(
        (user) => !exludedUsers.map((user) => user.email).includes(user.email),
      ),
    );
  }, [exludedUsers]);

  useImperativeHandle(ref, () => ({
    focus: () => tagInput.current?.focus(),
  }));

  const handleClickOutside = () => {
    setOpenAutoComplete(false);
  };

  useClickOutside(elementRef, handleClickOutside);

  useEffect(() => {
    if (isFocused) {
      tagInput.current?.focus();
    }
    onChangeTags(tags);
  }, [tags]);

  useEffect(() => {
    if (isClearTags) {
      clearTags();
      onClearTagsComplete();
    }
  }, [isClearTags, onClearTagsComplete]);

  // filter users by name or email except the selected tags
  const filterUsers = (users: User[], query: string) => {
    return users.filter((user) => {
      return (
        !tags.includes(user.email) &&
        (user.name?.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()))
      );
    });
  };

  const handleAddTag = (user: User) => {
    addTag(user.email);
    setOpenAutoComplete(false);
    if (tagInput.current) {
      tagInput.current.value = "";
      tagInput.current.focus();
    }
    if (tagUlRef.current) {
      tagUlRef.current.lastElementChild?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAddTagFromWhitelist = (email: string) => {
    // add the first email in the whitelistEmails
    addTag(email);
    // clear whitelistEmails
    setWhitelistEmails([]);
    // clear the input value
    if (tagInput.current) {
      tagInput.current.value = "";
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    inputKeyDown(e);
    onChangeTags(tags);

    const val = (e.target as HTMLInputElement).value;
    // check if there is whitelistEmails and key is Tab or Space with some value
    if (
      !tags.includes(val) &&
      !exludedUsers.map((user) => user.email).includes(val)
    ) {
      if (whitelistEmails.length > 0) {
        if (e.key === "Tab") {
          handleAddTagFromWhitelist(whitelistEmails[0]);
          // prevent default
          e.preventDefault();
        }
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenAutoComplete(true);
    setApprovedUsers(filterUsers(users, e.target.value));

    if (e.target.value.length <= 0) {
      setOpenAutoComplete(false);
    }

    // check if value length > 0 and is valid email and value is not in the tags and not in exludedUsers and not in approvedUsers
    if (
      e.target.value.length > 0 &&
      isValidEmail(e.target.value) &&
      !tags.includes(e.target.value) &&
      !exludedUsers.map((user) => user.email).includes(e.target.value) &&
      !approvedUsers.map((user) => user.email).includes(e.target.value) &&
      !users.map((user) => user.email).includes(e.target.value)
    ) {
      // set timeout then add the email to the whitelistEmails
      setTimeout(() => {
        // filter duplicate email
        if (!whitelistEmails.includes(e.target.value)) {
          setWhitelistEmails([e.target.value]);
        }
      }, 500);
    } else {
      // clear the whitelistEmails
      setWhitelistEmails([]);
    }
  };

  return (
    <div
      ref={elementRef}
      className={
        "bg-transparent relative flex flex-wrap " +
        containerClassName +
        (error ? " border-red-500" : "")
      }
    >
      <ul
        ref={tagUlRef}
        className="inline-flex items-center flex-wrap w-full p-0 m-0 overflow-y-auto max-h-100"
      >
        {tags.map((tag, i) => (
          <li
            key={tag}
            className="flex items-center last:items-start gap-4 bg-soft-purple-redx rounded-4 text-blue-purple-redx text-12 list-none me-5 mt-5 h-20 py-2 px-8"
          >
            {tag}
            <Icon
              icon="mdi:remove"
              className="w-15 h-15"
              onClick={() => {
                removeTag(i);
              }}
            />
          </li>
        ))}
      </ul>
      <div className="flex-grow bg-none"></div>
      <input
        ref={tagInput}
        {...props}
        type={type}
        className={
          "bg-transparent !border-transparent !focus:border-transparent !focus:ring-0 placeholder:text-14 placeholder-[#E2E2E2] w-full " +
          className
        }
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      {openAutoComplete && (
        <div
          className=" rounded-10 w-full bg-white text-black-redx absolute top-full z-50 mt-5"
          style={{
            boxShadow: "0px 0px 12px 0px #00000026",
          }}
        >
          <div className="flex flex-col overscroll-auto overflow-y-auto max-h-250">
            {approvedUsers.map((user, _) => (
              <div
                key={user.email}
                onClick={() => handleAddTag(user)}
                className="rounded-10 flex items-center gap-10 py-10 px-10 cursor-pointer hover:bg-soft-purple-redx"
              >
                <img
                  className="w-32 h-32 rounded-full"
                  src={user.avatar_url}
                  alt="image"
                />
                <div className="font-medium">
                  <div className="text-14 font-semibold leading-16 text-black-redx mb-5">
                    {user.name}
                  </div>
                  <div className="text-12 leading-14 text-grey-redx">
                    {user.email}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* show whitelist email suggestion when openAutoComplete and approvedUsers is < 0 */}
      {approvedUsers.length <= 0 && whitelistEmails.length > 0 && (
        <div
          className="rounded-10 w-full bg-white text-black-redx absolute top-full z-50 mt-5"
          style={{
            boxShadow: "0px 0px 12px 0px #00000026",
          }}
        >
          <div className="flex flex-col overscroll-auto overflow-y-auto max-h-250">
            {whitelistEmails.map((email, _) => (
              <div
                key={email}
                onClick={() => handleAddTagFromWhitelist(email)}
                className="rounded-10 flex items-center gap-10 py-10 px-10 cursor-pointer hover:bg-soft-purple-redx"
              >
                <Icon icon="mdi:user-circle" className="w-40 h-40" />
                <div className="font-medium">
                  <div className="text-14 font-semibold leading-16 text-black-redx mb-5">
                    {email}
                  </div>
                  <div className="text-12 leading-14 text-grey-redx">
                    {email}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});
