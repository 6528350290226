import HomeRoutes from "./Home";
import NotFoundRoutes from "./NotFound";
import AuthRoutes from "./Auth";
import Projects from "./Projects";
import SubmissionRoutes from "./Submission";
import MarketResearchRoutes from "./MarketResearch";
import AudienceAchetypeRoutes from "./AudienceAchetype";
import KeyInsightRoutes from "./KeyInsights";
import TargetAudienceRoutes from "./TargetAudience";
import CompetitorAnalysisRoutes from "./CompetitorAnalysis";
import ProductResearchRoutes from "./ProductResearch";
import ChallengeAndTaskRoutes from "./ChallengeAndTask";
import OpportunityAmongCompetitionRoutes from "./OpportunityAmongCompetition";
import OnePageStrategyRoutes from "./OnePageStrategy";
import KeyTouchPointRoutes from "./KeyTouchPoint";

const routes = [
  ...HomeRoutes,
  ...AuthRoutes,
  ...Projects,
  ...SubmissionRoutes,
  ...MarketResearchRoutes,
  ...AudienceAchetypeRoutes,
  ...KeyInsightRoutes,
  ...TargetAudienceRoutes,
  ...ProductResearchRoutes,
  ...CompetitorAnalysisRoutes,
  ...OpportunityAmongCompetitionRoutes,
  ...ChallengeAndTaskRoutes,
  ...OnePageStrategyRoutes,
  ...KeyTouchPointRoutes,
  ...NotFoundRoutes,
];

export default routes;
