import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { ProjectProps } from "@/Types/projects";

const fetchUser = async () => {
  try {
    const response = await fetcher.get("/user");
    return response.data as ProjectProps[];
  } catch (error: any) {
    throw new Error(error.response.data.message || "Failed to fetch user data");
  }
};

const useUser = () => {
  return useQuery<any, Error>({
    queryKey: ["user"],
    queryFn: fetchUser,
    refetchOnMount: false,
  });
};

export default useUser;
