import React from "react";
import PrimaryButton from "@/Components/PrimaryButton";
import { joinEmails } from "@/Utils/helper";

const RequestAccessModal: React.FC<{
  emails: string[];
  roles: string[];
  isOpen: boolean;
  onClose: () => void;
  onClickRequest: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ emails, isOpen, onClose, onClickRequest }) => {
  if (isOpen == false) {
    return null;
  }

  return (
    <div className="flex items-center justify-center w-full h-full z-20 absolute inset-0 overflow-y-auto top-0 bg-black/40">
      <div className="relative overflow-hidden px-20 py-20 max-w-md transition-all transform bg-white rounded-2xl">
        {joinEmails(emails)} did not have access for Ainstein.
        <div className="flex justify-end items-center mt-30 gap-x-10">
          <PrimaryButton
            className="text-12 ms-4 px-20 py-10 bg-transparent !text-black-redx font-semibold"
            onClick={onClose}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            className="text-12 ms-4 px-20 py-10 border-1 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
            onClick={(e) => onClickRequest(e)}
          >
            Request Access
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default RequestAccessModal;
