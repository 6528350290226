import PrimaryButton from "@/Components/PrimaryButton";

const UnsavedChangesPopup = ({
  onClose,
  onBack,
}: {
  onClose: () => void;
  onBack: () => void;
}) => {
  return (
    <div className="absolute right-10 top-10 mr-10 border-gray-100 border-1 shadow-md w-1/2 bg-white z-40 p-10 rounded-10 transition-transform transform duration-500">
      <div className="flex flex-col gap-3 p-5">
        <h1 className="font-semibold text-sm">Unsaved changes detected</h1>
        <p className="text-gray-500 text-xs">
          Are you sure want to close this pop up? Any changes you've made won't
          be saved if you do.
        </p>
        <div className="flex gap-16 justify-end py-4">
          <PrimaryButton
            className="!text-16 !leading-18 ms-4 px-31 py-10 bg-white hover:bg-white !text-blue-redx border-1 border-blue-redx active:bg-white"
            onClick={onClose}
          >
            Close Anyway
          </PrimaryButton>
          <PrimaryButton
            className="!text-16 !leading-18 ms-4 px-31 py-10 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
            onClick={onBack}
          >
            Back
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UnsavedChangesPopup;
