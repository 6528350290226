import React from "react";
import PrimaryButton from "@/Components/PrimaryButton";

const TransferOwnershipModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onClickRequest: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}> = ({ isOpen, onClose, onClickRequest }) => {
  if (isOpen == false) {
    return null;
  }

  return (
    <div className="flex items-center justify-center w-full h-full z-20 absolute inset-0 overflow-y-auto top-0 bg-black/40">
      <div className="relative overflow-hidden px-20 py-20 max-w-md transition-all transform bg-white rounded-2xl">
        <p className="font-semibold text-14 leading-16 text-black-redx mb-8">
          Change ownership project
        </p>
        <p className="font-normal text-12 leading-14 text-grey-redx">
          Are you sure transfer ownership to this member? Your role will change
          to <span className="font-semibold">view-only</span> automatically.
        </p>
        <div className="flex justify-end items-center mt-30 gap-x-10">
          <PrimaryButton
            className="text-12 ms-4 px-20 py-10 bg-transparent border-1 border-blue-redx !text-blue-redx"
            onClick={onClose}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            className="text-12 ms-4 px-20 py-10 border-1 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
            onClick={(e) => onClickRequest(e)}
          >
            Transfer Ownership
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default TransferOwnershipModal;
