import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArchetypeSchema, Schema } from "@/Types/audience-archetype/schema";
import CardInput from "@/Components/Projects/SliderCard/CardInput";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import { Archetype } from "@/Types/audience-archetype";
import { CardHandles } from "../Index";
import { ProjectProps } from "@/Types/projects";
import { useUpdateSelectedArchetype } from "@/Hooks/react-query/audience-archetype/useUpdateSelectedArchetype";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";

const Card = forwardRef<
  CardHandles,
  {
    project: ProjectProps;
    totalSelected: number;
    id: number;
    totalArchetype: number;
    archetype: any;
    selectedArchetype?: Archetype | null;
    index: number;
    activeIndex: number;
    isEditing: boolean;
    isFetching?: boolean;
    isSelected: boolean;
    onSubmitSuccess: (data: Schema, id: number, index: number) => void;
    onSelectSuccess: (isShowPopUp: boolean) => void;
  }
>(
  (
    {
      project,
      totalSelected,
      isFetching = false,
      id,
      totalArchetype,
      archetype,
      index,
      isEditing,
      isSelected,
      onSelectSuccess,
      onSubmitSuccess,
    },
    ref,
  ) => {
    const [isSelectedButton, setIsSelectedButton] = useState(true);
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedArchetype();
    const { refetch: refetchArchetypes } = useDifferentArchetypes(project);

    const defaultValues: Schema = useMemo(
      () => ({
        archetype_name: archetype.archetype_name ?? "",
        demographics: archetype.demographics ?? "",
        occupation: archetype.occupation ?? "",
        lifestyle: archetype.lifestyle ?? "",
        behavior: archetype.behavior ?? "",
        needs_and_challenges: archetype.needs_and_challenges ?? "",
        potential_buying_motives: archetype.potential_buying_motives ?? "",
        current_trends: archetype.current_trends ?? "",
        source_of_information: archetype.source_of_information ?? "",
        online_search_behavior: archetype.online_search_behavior ?? "",
        purchase_frequency: archetype.purchase_frequency ?? "",
        preferred_sales_channels: archetype.preferred_sales_channels ?? "",
      }),
      [archetype],
    );

    const onSubmit = (data: Schema) => {
      onSubmitSuccess(data, id, index);
    };

    const { handleSubmit, reset, control } = useForm<Schema>({
      resolver: zodResolver(ArchetypeSchema),
      defaultValues,
      mode: "all",
    });

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit((formData) => onSubmit(formData))(),
      }),
      [handleSubmit, onSubmit],
    );

    useEffect(() => {
      reset(defaultValues);
    }, [isEditing]);

    useEffect(() => {
      setIsSelectedButton(!isSelected && totalSelected >= 3);
    }, [totalSelected, isSelected]);

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data: any) => {
            onSelectSuccess(data.data.is_show_pop_up);
            refetchArchetypes();
          },
        },
      );
    };

    return (
      <>
        <form className='flex flex-col w-full transition duration-500 mb-45'>
          {!isFetching && (
            <div className='py-15 px-35 text-center'>
              <h1 className='text-1620 font-bold'>
                Archetype {index + 1} of {totalArchetype}
              </h1>
            </div>
          )}

          {isFetching && (
            <h1 className='w-3/12 h-20 animate-pulse bg-soft-purple-redx rounded-full mx-auto mb-14' />
          )}
          <div
            className={`p-24 grid grid-cols-3 gap-24 mb-16 transition duration-500 text-black-redx ${
              isEditing && isSelected ? "!bg-transparent" : ""
            } ${
              isSelected
                ? "border-t-2 border-blue-redx bg-softer-purple-redx"
                : "border-t-1 border-stroke-redx"
            }`}>
            {Object.keys(ArchetypeSchema.shape).map((field, idx) => (
              <Controller
                control={control}
                name={field as keyof Schema}
                key={idx}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const title =
                    field === "purchase_frequency"
                      ? "Purchase Frequency"
                      : field.charAt(0).toUpperCase() +
                        field.slice(1).replace(/_/g, " ");

                  return (
                    <CardInput
                      isFetching={isFetching}
                      isEditing={isEditing}
                      value={value}
                      defaultValue={archetype[field]}
                      error={error?.message}
                      placeholder={`Input the ${field.replace(/_/g, " ")} here`}
                      title={title}
                      onChange={onChange}
                    />
                  );
                }}
              />
            ))}
          </div>
          {id !== -1 && (
            <SelectButton
              index={index}
              isLoading={isUpdatingSelected}
              isEditing={isEditing}
              isSelected={isSelected}
              isDisabled={isSelectedButton}
              onSelect={() => handleSelected(id)}
            />
          )}
        </form>
      </>
    );
  },
);

export default Card;
