import React, { Dispatch, useContext, useEffect, useReducer } from "react";

import { useLocalStorage } from "@/Hooks/usePersist";
import reducer, { initialState, State } from "./reducer/projectReducer";
import { ProjectAction } from "./actions/projectActions";

const ProjectContext = React.createContext<[State, Dispatch<ProjectAction>]>([
  initialState,
  () => {},
]);

interface ProjectProviderProps {
  children: React.ReactNode;
}

const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [value, setValue] = useLocalStorage("project");
  const initialStore = Object.keys(value)?.length > 0 ? value : initialState;
  const store = useReducer(reducer, initialStore);

  useEffect(() => {
    setValue(store[0]);
  }, [setValue, store, value]);

  return (
    <ProjectContext.Provider value={store}>{children}</ProjectContext.Provider>
  );
};

const ProjectConsumer = ProjectContext.Consumer;

const useProject = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProject must be used within a ProjectProvider");
  }
  return context;
};

export { ProjectProvider, ProjectConsumer, useProject, ProjectContext };
