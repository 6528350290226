import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import {
  ProjectAction,
  setChallengeAndTaskData,
  setChallengeAndTaskLoading,
} from "@/Context/actions/projectActions";

import { SubsectionDataType } from "@/Types/projects";
import { ChallengeProps } from "@/Types/challenge_and_task";
import { fetcher } from "@/Services/axios";

export const handleGenerateChallengeAndTask =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    try {
      dispatch(
        setChallengeAndTaskLoading({
          status: true,
          progress: 0,
        }),
      );
      const interval = handleProgress((progress: number) =>
        dispatch(
          setChallengeAndTaskLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/challenge-and-task/generate`,
        {
          generate_more: regenerate_prompt,
        },
      );
      clearInterval(interval);
      dispatch(
        setChallengeAndTaskLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setChallengeAndTaskLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setChallengeAndTaskData(data.data));
      }, 1000);
    } catch (err) {
      dispatch(
        setChallengeAndTaskLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleSetChallengeAndTaskData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setChallengeAndTaskData(data));
  };
