import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { SubmissionResponse } from "@/Types/submission";

const fetchSubmission = async (
  projectSlug: string,
): Promise<SubmissionResponse> => {
  try {
    const response = await fetcher.get("/" + projectSlug + "/submission");
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch submisison data",
    );
  }
};

const useSubmission = (projectSlug: string) => {
  return useQuery<SubmissionResponse, Error>({
    queryKey: ["submission", projectSlug],
    queryFn: () => fetchSubmission(projectSlug),
  });
};

export { useSubmission };
