import { useEffect } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { fetcher } from "@/Services/axios";
import toast from "react-hot-toast";

type FormData = {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export default function Register() {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    setFocus("name");
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    // Use Inertia's post method for form submission
    try {
      fetcher.post("/register", data);
    } catch (error) {
      toast.error("Error register!");
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Register</title>
      </Helmet>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputLabel htmlFor="name" value="Name" />

          <TextInput
            id="name"
            className="mt-1 block w-full"
            autoComplete="name"
            isFocused={true}
            {...register("name", { required: "Name is required" })}
          />

          <InputError message={errors.name?.message} className="mt-2" />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="email" value="Email" />

          <TextInput
            id="email"
            type="email"
            className="mt-1 block w-full"
            autoComplete="username"
            {...register("email", { required: "Email is required" })}
          />

          <InputError message={errors.email?.message} className="mt-2" />
        </div>

        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            id="password"
            type="password"
            className="mt-1 block w-full"
            autoComplete="new-password"
            {...register("password", { required: "Password is required" })}
          />

          <InputError message={errors.password?.message} className="mt-2" />
        </div>

        <div className="mt-4">
          <InputLabel
            htmlFor="password_confirmation"
            value="Confirm Password"
          />

          <TextInput
            id="password_confirmation"
            type="password"
            className="mt-1 block w-full"
            autoComplete="new-password"
            {...register("password_confirmation", {
              required: "Password confirmation is required",
            })}
          />

          <InputError
            message={errors.password_confirmation?.message}
            className="mt-2"
          />
        </div>

        <div className="flex items-center justify-end mt-4">
          <Link
            to="/login"
            className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Already registered?
          </Link>

          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Register
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
}
