import { Icon } from "@iconify/react/dist/iconify.js";

type SelectedFrameworkCardProps = {
  selectedFramework: "Marketing Funnel" | "S.D.R.C.P";
  onCLick: () => void;
};

const SelectedFrameworkCard = ({
  selectedFramework,
  onCLick,
}: SelectedFrameworkCardProps) => {
  return (
    <div className='bg-softer-purple-redx border-1 border-t-blue-redx border-b-blue-redx p-16 md:flex-col md:flex inline-flex justify-between gap-16'>
      <div className='flex flex-col gap-8'>
        <p className='font-bold text-16 leading-19'>
          Selected Framework: {selectedFramework}
        </p>
        {selectedFramework === "Marketing Funnel" && (
          <p className='font-normal text-black-redx text-14 leading-16'>
            The marketing funnel guides potential customers from{" "}
            <strong>awareness</strong> to advocacy, focusing on attracting
            attention, encouraging <strong>consideration</strong> , driving{" "}
            <strong>conversions</strong>, fostering{" "}
            <strong>purchase intent</strong>, and promoting brand{" "}
            <strong>advocacy</strong>.
          </p>
        )}
        {selectedFramework == "S.D.R.C.P" && (
          <p className='font-normal text-black-redx text-14 leading-16'>
            The S.D.R.C.P framework ensures a product's online presence is
            easily <strong>searchable</strong>, <strong>discoverable</strong>{" "}
            across various channels,
            <strong>reachable</strong> through multiple touchpoints,
            <strong>credible</strong> through trust-building measures, and
            simple to <strong>purchase</strong>.
          </p>
        )}
      </div>
      <p
        className='text-blue-redx inline-flex gap-4 font-semibold justify-center items-center text-16 leading-18 shrink-0 cursor-pointer'
        onClick={onCLick}>
        Change Framework
        <Icon
          icon='lucide:refresh-cw'
          className={"size-20"}
        />
      </p>
    </div>
  );
};

export default SelectedFrameworkCard;
