interface FormattedInformation {
  className: string;
  content: string;
}

export function getRandomString(length: number = 10): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function isValidEmail(value: string): boolean {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(value);
}

export function joinEmails(emails: string[]): string {
  if (emails.length < 2) return emails.join("");
  return emails.slice(0, -1).join(", ") + " and " + emails[emails.length - 1];
}

export function formatInformation(information: string): FormattedInformation {
  const classNameMatch = information.match(/\{([^}]+)\}/);
  const className = classNameMatch ? classNameMatch[1] : "";
  const info = information.replace(/\{[^}]+\}\s*/, "");

  return { className, content: info };
}

export function isYourRolesCanEdit(roles: string[]): boolean {
  // return false if roles include "View Only", "Content" or "Creative"
  return (
    !roles.includes("View Only") &&
    !roles.includes("Content") &&
    !roles.includes("Creative")
  );
}
