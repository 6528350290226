import { ChallengeProps } from "@/Types/challenge_and_task";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import TextAreaInput from "@/Components/TextAreaInput";
import { useForm, Controller } from "react-hook-form";
import { Challenge, ChallengeSchema } from "@/Types/challenge_and_task/schema";
import { CardHandles } from "../Index";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProjectProps } from "@/Types/projects";
import SelectButton from "@/Components/Projects/SliderCard/SelectButton";
import { useUpdateSelectedChallengeAndTask } from "@/Hooks/react-query/challenge-and-task";
import MarkdownView from "@/Components/MardownView";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";

const Card = forwardRef<
  CardHandles,
  {
    id: number;
    project: ProjectProps;
    totalData: number;
    isFetching?: boolean;
    item: ChallengeProps;
    activeIndex: number;
    index: number;
    isEditing: boolean;
    isSelected: boolean;
    isAdding?: boolean;
    isCanSelect?: boolean;
    onSubmitSuccess: (data: ChallengeSchema, id: number, index: number) => void;
    onSelectSuccess: (data: any) => void;
  }
>(
  (
    {
      id,
      project,
      totalData,
      item,
      isFetching = false,
      index,
      isSelected,
      isEditing,
      isAdding = false,
      isCanSelect = false,
      onSubmitSuccess,
      onSelectSuccess,
    },
    ref,
  ) => {
    const { mutate: mutateSelected, isPending: isUpdatingSelected } =
      useUpdateSelectedChallengeAndTask();
    const { refetch } = useDifferentArchetypes(project);

    const { handleSubmit, reset, control } = useForm<ChallengeSchema>({
      resolver: zodResolver(Challenge),
      defaultValues: {
        challenge: item.challenge ?? "",
        communication_task: item.communication_task ?? "",
      },
      mode: "all",
    });

    const onSubmit = (data: ChallengeSchema) => {
      onSubmitSuccess(data, id, index);
    };

    useEffect(() => {
      if (!isEditing) {
        reset({
          challenge: item.challenge ?? "",
          communication_task: item.communication_task ?? "",
        });
      }
    }, [isEditing, reset, item]);

    useImperativeHandle(
      ref,
      () => ({
        submitForm: () => handleSubmit(onSubmit)(),
      }),
      [handleSubmit, onSubmit],
    );

    const handleSelected = (id: number) => {
      const projectSlug = project.slug;

      mutateSelected(
        { id, projectSlug },
        {
          onSuccess: (data) => {
            const updatedItem = data.data;
            refetch();
            onSelectSuccess(updatedItem);
          },
        },
      );
    };

    return (
      <div className="flex flex-col w-full pb-35 transition duration-500">
        <div className="py-15 px-35 text-center">
          {!isFetching && isAdding && (
            <h1 className="text-15 font-bold">
              New Challenge & Communication Task
            </h1>
          )}

          {!isFetching && !isAdding && (
            <h1 className="text-15 font-bold">
              Challenge & Communication Task {index + 1} of {totalData}
            </h1>
          )}

          {isFetching && (
            <h1 className="w-3/12 h-20 animate-pulse bg-soft-purple-redx rounded-full mx-auto mb-14" />
          )}
        </div>
        <div
          className={`p-24 text-black-redx flex flex-col gap-24 ${
            isEditing && isSelected ? "!bg-transparent" : ""
          } ${
            isSelected
              ? "border-t-2 border-blue-redx bg-softer-purple-redx"
              : "border-t-1 border-stroke-redx"
          }`}
        >
          <div className="flex flex-col gap-10">
            <h3 className="font-semibold text-12 leading-14">Challenge</h3>
            {isEditing ? (
              <Controller
                name="challenge"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextAreaInput
                    error={error?.message}
                    value={value}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the challenge here"
                    autoComplete="off"
                    onChange={onChange}
                    isFocused={false}
                  />
                )}
              />
            ) : (
              !isFetching && (
                <div className="text-14 py-4 min-h-50">
                  <MarkdownView content={item.challenge} />
                </div>
              )
            )}
            {isFetching && (
              <div className="text-14 py-4 min-h-50">
                <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14" />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-10">
            <h3 className="font-semibold text-12 leading-14">
              Communication Task
            </h3>
            {isEditing ? (
              <Controller
                name="communication_task"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextAreaInput
                    error={error?.message}
                    value={value}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the communication task here"
                    autoComplete="off"
                    onChange={onChange}
                    isFocused={false}
                  />
                )}
              />
            ) : (
              !isFetching && (
                <div className="text-14 py-4 min-h-50">
                  <MarkdownView content={item.communication_task} />
                </div>
              )
            )}
            {isFetching && (
              <div className="text-14 py-4 min-h-50">
                <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14" />
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full justify-end px-1 pt-15">
          {id !== -1 && isCanSelect && (
            <SelectButton
              index={index}
              isLoading={isUpdatingSelected}
              isEditing={isEditing}
              isSelected={isSelected}
              onSelect={() => handleSelected(id)}
            />
          )}
        </div>
      </div>
    );
  },
);

export default Card;
