import React from "react";

import ModalCenter from "@/Components/Modals/Center";
import PrimaryButton from "@/Components/PrimaryButton";
import DOMPurify from "dompurify";

const NoAccessModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  message: string | null;
}> = ({ isOpen, onClose, message }) => {
  const sanitizedMessage = () => ({
    __html: DOMPurify.sanitize(message as string),
  });

  return (
    <ModalCenter isOpen={isOpen} onClose={onClose}>
      <div className={`relative w-full max-w-md min-w-486 md:min-w-[100vw]`}>
        <div className="w-full rounded-10 relative pt-50 pb-35 px-35">
          <h1 className="font-medium text-25 text-black-redx leading-30 mb-15">
            You need an invitation
          </h1>
          <p
            className="prose mb-25 text-15 text-grey-redx leading-20"
            dangerouslySetInnerHTML={sanitizedMessage()}
          ></p>
          <div className="flex w-full justify-end">
            <PrimaryButton
              className="text-12 ms-4 px-20 py-10 bg-blue-redx hover:bg-indigo-700 active:bg-indigo-700"
              onClick={onClose}
            >
              Understand
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default NoAccessModal;
