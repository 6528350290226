import { SubsectionDataType, LoadingStatus } from "@/Types/projects";
import {
  SaveGeneratedDataAction,
  SaveLoadingAction,
} from "@/Context/actions/projectActions";
import { ProjectActionsTypes } from "@/Context/actions/projectActionsType";

export const setTargetAudienceAudienceInsights = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS,
    payload: data,
  };
};

export const setTargetAudienceAudienceInsightsLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_AUDIENCE_INSIGHTS_LOADING,
    payload: isLoading,
  };
};

export const setTargetAudienceBehavioralTrends = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS,
    payload: data,
  };
};

export const setTargetAudienceBehavioralTrendsLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_BEHAVIORAL_TRENDS_LOADING,
    payload: isLoading,
  };
};

export const setTargetAudienceMediaConsumptionPatterns = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS,
    payload: data,
  };
};

export const setTargetAudienceMediaConsumptionPatternsLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_MEDIA_CONSUMPTION_PATTERNS_LOADING,
    payload: isLoading,
  };
};

export const setTargetAudiencePerceptionAnalysis = (
  data: SubsectionDataType,
): SaveGeneratedDataAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS,
    payload: data,
  };
};

export const setTargetAudiencePerceptionAnalysisLoading = (
  isLoading: LoadingStatus,
): SaveLoadingAction => {
  return {
    type: ProjectActionsTypes.SET_TARGET_AUDIENCE_PERCEPTION_ANALYSIS_LOADING,
    payload: isLoading,
  };
};
