import PrimaryButton from "@/Components/PrimaryButton";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/projects`);
  };
  return (
    <AuthenticatedLayout>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div className="my-6">
          <img
            src="/assets/img/404-error.png"
            alt="Confused Character"
            className="w-auto h-300 mx-auto"
          />
        </div>
        <div className="text-center mt-6">
          <div className="mb-6">
            <h1 className="text-30 font-semibold">Page not found...</h1>
            <p className="text-gray-400 py-20">
              Sorry, the page you were looking for is either removed or doesn't
              exist.
              <br />
              Please contact your teammates or our support team for help.
            </p>
          </div>

          <PrimaryButton
            className="text-18 px-30 font-semibold py-10 border-1 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
            onClick={handleClick}
          >
            Go to Project List
          </PrimaryButton>
        </div>
      </div>
    </AuthenticatedLayout>
  );
};
