import { PropsWithChildren } from "react";
import { ModalProvider } from "@/Context/ModalContext";
import { Toaster } from "react-hot-toast";

export default function Guest({ children }: PropsWithChildren) {
  return (
    <ModalProvider>
      <div className="relative w-full h-screen">
        <div className="absolute top-0 left-0 w-full h-full">
          <video
            className="w-full h-full fixed right-0 bottom-0 object-cover"
            playsInline
            autoPlay={true}
            loop={true}
            muted={true}
          >
            <source src="assets/video/bg-gradient.webm" type="video/webm" />
          </video>
        </div>

        <div className="absolute top-0 left-0 mt-50 ml-50 md:ml-0 md:inset-x-1/2">
          <img
            src="assets/img/ainstein-logo.png"
            className="flex justify-center h-45 w-auto"
          />
        </div>

        <div className="w-full h-full flex items-center justify-around container-xl">
          {children}
        </div>
      </div>
      <Toaster
        position="bottom-center"
        containerStyle={{
          bottom: 120,
        }}
        toastOptions={{
          duration: 5000,
        }}
      />
    </ModalProvider>
  );
}
