import { ProjectProps } from "@/Types/projects";
import { fetcher } from "@/Services/axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

const latestHistory = async (project: ProjectProps | string) => {
  const project_slug = typeof project === "string" ? project : project.slug;
  const url = `/${project_slug}/history/latest`;
  try {
    const response = await fetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "An error occurred");
  }
};

const useHistoryLatest = (
  project: ProjectProps | string,
): UseQueryResult<any, Error> => {
  const project_slug = typeof project === "string" ? project : project.slug;
  return useQuery<any, Error>({
    queryKey: ["latestHistory", project_slug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => latestHistory(project),
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchInterval: 6000,
  });
};

export default useHistoryLatest;
