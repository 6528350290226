import { Dispatch } from "react";
import axios from "axios";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import {
  setTargetAudienceAudienceInsights,
  setTargetAudienceAudienceInsightsLoading,
  setTargetAudienceBehavioralTrends,
  setTargetAudienceBehavioralTrendsLoading,
  setTargetAudienceMediaConsumptionPatterns,
  setTargetAudienceMediaConsumptionPatternsLoading,
  setTargetAudiencePerceptionAnalysis,
  setTargetAudiencePerceptionAnalysisLoading,
} from "@/Context/actions/target_audience";
import { fetcher } from "@/Services/axios";

export const handleGenerateTargetAudienceAudienceInsight =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setTargetAudienceAudienceInsightsLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setTargetAudienceAudienceInsightsLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/target-audience/generate/audience_insight`,
        { prompt: regenerate_prompt },
      );

      clearInterval(interval);
      dispatch(
        setTargetAudienceAudienceInsightsLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setTargetAudienceAudienceInsightsLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setTargetAudienceAudienceInsights(data.data));
      }, 1000);
    } catch (err) {
      clearInterval(interval);
      dispatch(
        setTargetAudienceAudienceInsightsLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateTargetAudienceBehavioralTrend =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setTargetAudienceBehavioralTrendsLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setTargetAudienceBehavioralTrendsLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/target-audience/generate/behavioral_trends`,
        { prompt: regenerate_prompt },
      );

      clearInterval(interval);
      dispatch(
        setTargetAudienceBehavioralTrendsLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setTargetAudienceBehavioralTrendsLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setTargetAudienceBehavioralTrends(data.data));
      }, 1000);
    } catch (err) {
      clearInterval(interval);
      dispatch(
        setTargetAudienceBehavioralTrendsLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateTargetAudienceMediaConsumptionPatterns =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setTargetAudienceMediaConsumptionPatternsLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setTargetAudienceMediaConsumptionPatternsLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/target-audience/generate/media_consumption_patterns`,
        { prompt: regenerate_prompt },
      );

      clearInterval(interval);
      dispatch(
        setTargetAudienceMediaConsumptionPatternsLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setTargetAudienceMediaConsumptionPatternsLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setTargetAudienceMediaConsumptionPatterns(data.data));
      }, 1000);
    } catch (err) {
      clearInterval(interval);
      dispatch(
        setTargetAudienceMediaConsumptionPatternsLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleGenerateTargetAudiencePerceptionAnalysis =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setTargetAudiencePerceptionAnalysisLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setTargetAudiencePerceptionAnalysisLoading({
            status: true,
            progress,
          }),
        ),
      );
      const { data } = await fetcher.post(
        `/${project_slug}/target-audience/generate/perception_analysis`,
        { prompt: regenerate_prompt },
      );

      clearInterval(interval);
      dispatch(
        setTargetAudiencePerceptionAnalysisLoading({
          status: true,
          progress: 100,
        }),
      );
      setTimeout(() => {
        dispatch(
          setTargetAudiencePerceptionAnalysisLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setTargetAudiencePerceptionAnalysis(data.data));
      }, 1000);
    } catch (err) {
      clearInterval(interval);
      dispatch(
        setTargetAudiencePerceptionAnalysisLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };
