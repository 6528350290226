import React from "react";
import ModalCenter from "@/Components/Modals/Center";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import PencilGenerate from "@/Components/Icon/pencil-generate";
import { ProjectProps } from "@/Types/projects";
import { useUpdateSelectedFramework } from "@/Hooks/react-query/submission/useUpdateSelectedFramework";
import { SelectedFramework } from "@/Types/key_touch_point";
import { useNavigate } from "react-router-dom";
import MarkdownView from "@/Components/MardownView";
import useProjectDetail from "@/Hooks/react-query/useProjectDetail";
import Cookies from "js-cookie";

const SelectingFunnel: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  project: ProjectProps;
}> = ({ isOpen, onClose, project }) => {
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const { mutate, isPending } = useUpdateSelectedFramework();
  const { refetch } = useProjectDetail(project.slug);
  const handleClick = () => {
    mutate(
      {
        projectSlug: project.slug,
        selectedFramework: selected as SelectedFramework,
      },
      {
        onSuccess: () => {
          Cookies.set("selected_framework", selected, { expires: 1 / 5760 });
          refetch();
          navigate(`/${project.slug}/key-touch-point`);
          onClose();
        },
      },
    );
  };

  const options = [
    {
      value: "marketing_funnel",
      title: "Marketing Funnel",
      description:
        "The marketing funnel guides potential customers from **awareness** to advocacy, focusing on attracting attention, encouraging **consideration**, driving **conversions**, fostering **purchase intent**, and promoting brand **advocacy.**",
    },
    {
      value: "sdrcp",
      title: "S.D.R.C.P",
      description:
        "The S.D.R.C.P framework ensures a product's online presence is easily **searchable**, **discoverable** across various channels, **reachable** through multiple touch points, **credible** through trust-building measures, and simple to **purchase.**",
    },
  ];

  const buttonNextDisabledClass =
    selected === "" || isPending
      ? "bg-placeholder-redx border-placeholder-redx cursor-not-allowed text-white"
      : `bg-button-gradient-90 cursor-pointer`;

  return (
    <ModalCenter
      hasButtonClose={false}
      isOpen={isOpen}
      onClose={onClose}>
      <div className='relative w-full max-w-3xl md:max-w-sm p-20 overflow-scroll md:h-538'>
        <div className='flex transition-transform transform duration-500'>
          <div className='w-full flex-shrink-0 p-10'>
            <div className='max-w-2xl mb-20'>
              <h1 className='font-bold md:text-24 text-35 text-black-redx mb-4'>
                Select your desired framework
              </h1>
              <p className='text-grey-redx text-15'>
                The framework you select will shape the touch points output
                based on the chosen archetype in audience archetype.
              </p>
            </div>
            <RadioGroup
              value={selected}
              onChange={setSelected}
              className='my-20 border-t-1 border-gray-300'>
              {options.map((option) => (
                <RadioGroup.Option
                  key={option.value}
                  value={option.value}
                  className={({ active, checked }) =>
                    `relative flex cursor-pointer rounded-m px-4 focus:outline-none
                      ${
                        checked
                          ? "bg-blue-50 border-blue-500"
                          : "bg-white border-gray-300"
                      } 
                      ${active ? "ring-2 ring-offset-2 ring-blue-500" : ""}`
                  }>
                  {({ checked }) => (
                    <>
                      <div
                        className={`flex items-center py-20 ${
                          checked
                            ? "border-b-3 border-t-3 border-blue-redx"
                            : "border-b-1 border-gray-300"
                        }`}>
                        <div className='p-20'>
                          <span
                            className={`w-20 h-20 flex justify-items-center items-center justify-center rounded-full bg-white border-1 ${
                              checked ? "border-blue-redx" : "border-gray-300"
                            }`}>
                            {checked && (
                              <Icon
                                icon='bxs:circle'
                                className='h-12 w-12 text-blue-redx'
                              />
                            )}
                          </span>
                        </div>
                        <div className='text-sm'>
                          <RadioGroup.Label
                            as='p'
                            className={`font-bold md:text-24 text-30 text-gray-900`}>
                            {option.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as='span'
                            className={`text-black-redx `}>
                            <MarkdownView content={option.description} />
                          </RadioGroup.Description>
                        </div>
                      </div>
                      <div className='absolute right-0 flex items-center pr-4'>
                        <span className={`w-4 h-4 rounded-full `}></span>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div className='flex md:flex-col flex-row w-full gap-24 py-20'>
              <button
                className='w-1/2 md:w-full text-18 py-15 bg-transparent border-blue-redx border-2 text-blue-redx font-semibold rounded-10'
                onClick={onClose}>
                Close
              </button>
              <button
                disabled={isPending || selected === ""}
                className={`w-1/2 md:w-full text-18 px-24 py-15 border-2 gap-8 font-semibold rounded-lg text-white flex flex-row justify-center items-center ${buttonNextDisabledClass}`}
                onClick={handleClick}>
                Generate Key Touch Point <PencilGenerate />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default SelectingFunnel;
