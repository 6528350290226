interface iAvatarLabel {
  src: string;
  title: string;
  subtitle: string;
}

export default function AvatarLabel({ src, title, subtitle }: iAvatarLabel) {
  return (
    <div className="flex items-center gap-10">
      <img className="w-32 h-32 rounded-full" src={src} alt="image" />
      <div className="font-medium text-black">
        <div className="text-14 font-semibold leading-16 text-black-redx mb-5">
          {title}
        </div>
        <div className="text-12 text-grey-redx leading-14">{subtitle}</div>
      </div>
    </div>
  );
}
