import ProjectMembers from "@/Components/ProjectMembers";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { sessionStorage } from "@/Services/sessionStorage";
import { User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProjectCard: React.FC<{
  project: ProjectProps;
  initialUsers: User[];
  auth: { user: User };
}> = ({ project, initialUsers, auth }) => {
  const user = auth.user;
  const { Badge } = useRolesBadge(project, user);
  const navigate = useNavigate();

  // handle click to submission page
  const handleClick = () => {
    sessionStorage.set("projects", {
      project_slug: project.slug,
    });
    navigate(`/${project.slug}/submission`);
  };

  return (
    <div
      onClick={handleClick}
      className="container bg-white hover:bg-[#F6F4FF] border-blue-purple-redx border-1 p-24 rounded-8 cursor-pointer"
    >
      <div className="flex items-center gap-20 border-b-1 border-b-stroke-redx pb-18">
        <div className="grow">
          {/* project title and date created */}
          <div className="flex items-center gap-10 mb-18">
            <h2 className="md:w-1/2 text-24 text-black-redx font-semibold leading-28 line-clamp-2 sm:text-20 sm:leading-24">
              {project.name}
            </h2>
            <div className="flex items-center justify-between gap-10">
              <div className="h-1 bg-stroke-redx w-16">&nbsp;</div>
              <p className="md:w-1/2 flex-1 text-16 leading-20 text-grey-redx sm:text-14 sm:leading-18">
                {moment(project.created_at).format("DD MMMM YYYY")}
              </p>
            </div>
          </div>
          <div className="flex -space-x-2">
            {/* members of project */}
            <ProjectMembers project={project} initialUsers={initialUsers} />
          </div>
        </div>
        <div className="">
          {/* role project */}
          <Badge />
        </div>
      </div>
      {/* project fields */}
      {project.submissions.map((submission, i) => {
        return (
          <div key={i} className="grid grid-cols-3 gap-20 mt-20">
            <div>
              <div className="text-grey-redx text-12 leading-15 font-semibold">
                Brand Name
              </div>
              <div className="text-black-redx text-16 leading-20 font-normal">
                {submission.brand_name}
              </div>
            </div>
            <div>
              <div className="text-grey-redx text-12 leading-15 font-semibold">
                Product Name
              </div>
              <div className="text-black-redx text-16 leading-20 font-normal">
                {submission.product_name}
              </div>
            </div>
            <div>
              <div className="text-grey-redx text-12 leading-15 font-semibold">
                Product Category
              </div>
              <div className="text-black-redx text-16 leading-20 font-normal">
                {submission.category?.name}
              </div>
            </div>
            <div>
              <div className="text-grey-redx text-12 leading-15 font-semibold">
                Main Objective
              </div>
              <div className="text-black-redx text-16 leading-20 font-normal">
                {submission.objective}
              </div>
            </div>
            <div>
              <div className="text-grey-redx text-12 leading-15 font-semibold">
                Audience Archetype
              </div>
              <div className="text-black-redx text-16 leading-20 font-normal">
                {project.audience_archetypes}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectCard;
