import React, { useEffect, useState, Fragment } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import TextAreaInput from "@/Components/TextAreaInput";
import { CompetitorAnalysisProps } from "@/Types/competitor_analysis";
import EditButton from "@/Components/Projects/EditButton";
import { Icon } from "@iconify/react";
import MarkdownView from "@/Components/MardownView";

const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => (
  <div className={`flex flex-col gap-10 ` + className}>{children}</div>
);
const Label: React.FC<{ value: string }> = ({ value }) => (
  <p className="text-12 leading-14 font-semibold text-black-redx">{value}</p>
);
const Content: React.FC<{ 
  value: string | string[];
  isFetching?: boolean;
}> = ({ value, isFetching = false }) => {
  if (isFetching) {
    return (
      <div className="flex flex-col gap-10">
        <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full' />
      </div>
    );
  }
  if (Array.isArray(value)) {
    return (
      <ul className="list-disc ms-20">
        {value.map((item, index) => (
          <li
            key={index}
            className="text-14 leading-20 font-normal text-black-redx"
          >
            <MarkdownView content={item} />
          </li>
        ))}
      </ul>
    );
  }
  return (
    <p className="text-14 leading-20 font-normal text-black-redx">
      <MarkdownView content={value} />
    </p>
  );
};
const Card: React.FC<{
  item: CompetitorAnalysisProps;
  index: number;
  isCanEdit?: boolean;
  isFetching?: boolean;
  onChangedData?: (data: CompetitorAnalysisProps, index: number) => void;
  onSaveEditedData?: (data: CompetitorAnalysisProps, index: number) => void;
}> = ({ item, index, isCanEdit = true, isFetching = false, onSaveEditedData }) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CompetitorAnalysisProps>({
    defaultValues: {
      brand_name: item.brand_name ?? "",
      product_name: item.product_name ?? "",
      communication_angle: item.communication_angle ?? "",
      communication_idea: item.communication_idea ?? "",
      communication_tone: item.communication_tone ?? "",
      features_benefits: item.features_benefits ?? "",
      key_touch_points: item.key_touch_points ?? "",
      opportunities: item.opportunities ?? "",
    },
  });

  const toggleIsEditing = () => setIsEditing(!isEditing);

  useEffect(() => {
    reset(item);
  }, [item, reset]);

  const handleCancelEdit = () => {
    reset();
    toggleIsEditing();
  };

  const onSubmit: SubmitHandler<CompetitorAnalysisProps> = (data) => {
    toggleIsEditing();
    if (onSaveEditedData) {
      onSaveEditedData(data, index);
    }
  };

  return (
    <div className={`flex flex-col w-full pb-35`}>
      <div className='py-15 text-left border-b-1 border-stroke-redx mb-15'>
        {!isFetching && (
          <h1 className='text-16 leading-20 text-black-redx font-bold'>
            Competitor {index + 1}
          </h1>
        )}

        {isFetching && (
          <h1 className='w-2/12 h-20 animate-pulse bg-soft-purple-redx rounded-full' />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`grid grid-cols-2 gap-24 pb-10`}>
          <Container>
            <Label value="Brand Name" />
            {isEditing ? (
              <Controller
                name="brand_name"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.brand_name?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the brand name here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.brand_name} isFetching={isFetching}/>
            )}
          </Container>
          <Container>
            <Label value="Product Name" />
            {isEditing ? (
              <Controller
                name="product_name"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.product_name?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the product name here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.product_name} isFetching={isFetching}/>
            )}
          </Container>
          <Container>
            <Label value="Communication Angle" />
            {isEditing ? (
              <Controller
                name="communication_angle"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.communication_angle?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the communication angle here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.communication_angle} isFetching={isFetching}/>
            )}
          </Container>
          <Container>
            <Label value="Communication Idea" />
            {isEditing ? (
              <Controller
                name="communication_idea"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.communication_idea?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the communication idea here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.communication_idea} isFetching={isFetching}/>
            )}
          </Container>
          <Container>
            <Label value="Communication Tone" />
            {isEditing ? (
              <Controller
                name="communication_tone"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.communication_tone?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={2}
                    placeholder="Input the communication tone here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.communication_tone} isFetching={isFetching}/>
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Features & Benefits" />
            {isEditing ? (
              <Controller
                name="features_benefits"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.features_benefits?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={3}
                    placeholder="Input the features and benefits here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.features_benefits as string | string[]} isFetching={isFetching}/>
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Key Touch Points" />
            {isEditing ? (
              <Controller
                name="key_touch_points"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.key_touch_points?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={3}
                    placeholder="Input the key touch points here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.key_touch_points} isFetching={isFetching}/>
            )}
          </Container>
          <Container className="col-span-2">
            <Label value="Opportunities" />
            {isEditing ? (
              <Controller
                name="opportunities"
                control={control}
                render={({ field }) => (
                  <TextAreaInput
                    {...field}
                    error={errors.opportunities?.message}
                    containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                    className="mt-1 block w-full !text-14 resize-none overflow-custom"
                    rows={3}
                    placeholder="Input the opportunities here"
                    autoComplete="off"
                    isFocused={false}
                  />
                )}
              />
            ) : (
              <Content value={item.opportunities} isFetching={isFetching}/>
            )}
          </Container>
        </div>
        <div
          className={`flex gap-x-15 w-full justify-end py-10 mt-10 ${
            !isEditing ? "border-t-1 border-t-stroke-redx" : ""
          }`}
        >
          {isCanEdit &&
            (isEditing ? (
              <Fragment>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
                  onClick={handleCancelEdit}
                >
                  <span className="text-15 font-semibold">Cancel</span>
                  <Icon icon="lucide:x" className="w-20 h-20" />
                </button>
                <button
                  type="submit"
                  className="inline-flex items-center gap-x-4 text-blue-redx cursor-pointer"
                >
                  <span className="text-15 font-semibold">Save</span>
                  <Icon icon="lucide:save" className="w-20 h-20" />
                </button>
              </Fragment>
            ) : (
              <EditButton toggleEditing={toggleIsEditing} />
            ))}
        </div>
      </form>
    </div>
  );
};

export default Card;
