import { forwardRef, useContext, useState } from "react";
import { Icon } from "@iconify/react";

import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import TextAreaInput from "@/Components/TextAreaInput";
import { ProjectContext } from "@/Context/ProjectContext";
import RegenerateModal from "./RegenerateModal";

import { FieldErrors, UseFormSetValue } from "react-hook-form";
import { SubmissionFormPayload } from "@/Types/form";

interface ProjectObjectiveProps {
  data: {
    kpi: string;
    objective: string;
    second_objective: string;
  };
  setData: UseFormSetValue<SubmissionFormPayload>;
  onEditSection: (sectionIndex: number) => Promise<boolean>;
  fetchData: () => void;
  errors: FieldErrors<SubmissionFormPayload>;
  setError: any;
  isActive: boolean;
  isEditor: boolean;
  isFetching: boolean;
}

const ProjectObjective = forwardRef<HTMLDivElement, ProjectObjectiveProps>(
  (
    {
      data,
      setData,
      errors,
      setError,
      isActive,
      onEditSection,
      fetchData,
      isEditor,
      isFetching,
    },
    ref,
  ) => {
    const [state] = useContext(ProjectContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const submitForm = async () => {
      setIsModalOpen(false);
      const isNotError = await onEditSection(4);
      if (!isNotError) {
        setIsEditing(true);
        return;
      }
      setIsEditing(false);
    };

    return (
      <div
        ref={ref}
        className={`flex flex-col w-full mb-24 ${
          isActive ? "" : "cursor-not-allowed opacity-30"
        }`}
      >
        <div className="flex justify-between items-center w-full">
          <h1 className="font-bold text-24 leading-32 mb-15">
            Campaign Objective
          </h1>
          {!isFetching &&
            state.submission.is_objective_information_complete &&
            !isEditing &&
            isActive &&
            isEditor && (
              <Icon
                icon="lucide:pencil-line"
                className="text-blue-redx w-16 h-16 cursor-pointer"
                onClick={() => setIsEditing(true)}
              />
            )}
          {!isFetching && isEditing && isActive && isEditor && (
            <div
              className="inline-flex items-center gap-x-4 cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              <p className="text-14 font-semibold text-blue-redx">Save</p>
              <Icon icon="lucide:save" className="text-blue-redx w-16 h-16" />
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 w-full gap-24">
          <div className="">
            <InputLabel
              htmlFor="objective"
              value="Main Objective"
              className="!text-black-redx"
            />
            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_objective_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextAreaInput
                id="objective"
                name="objective"
                value={data.objective}
                error={errors.objective?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input main objective here"
                autoComplete="objective"
                disabled={!isActive}
                isFocused={false}
                onChange={(e: any) => {
                  setData("objective", e.target.value);
                  setError("objective", { message: "" });
                }}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.objective.length > 0 ? data.objective : "-"}
              </p>
            )}
          </div>
          <div className="">
            <InputLabel
              htmlFor="second_objective"
              value="Second Objective"
              className="!text-black-redx"
            />
            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_objective_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextAreaInput
                id="second_objective"
                name="second_objective"
                value={data.second_objective}
                error={errors.second_objective?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input second objective here"
                autoComplete="second_objective"
                disabled={!isActive}
                isFocused={false}
                onChange={(e: any) => {
                  setData("second_objective", e.target.value);
                  setError("second_objective", { message: "" });
                }}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.second_objective.length > 0 ? data.second_objective : "-"}
              </p>
            )}
            {}
          </div>
          <div className="">
            <InputLabel
              htmlFor="kpi"
              value="Campaign KPI Metrics (Optional)"
              className="!text-black-redx"
            />
            {isFetching ? (
              <div className="w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mt-8" />
            ) : (!state.submission.is_objective_information_complete ||
                isEditing) &&
              isEditor ? (
              <TextAreaInput
                id="kpi"
                name="kpi"
                value={data.kpi}
                error={errors.kpi?.message}
                containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                className="mt-1 block w-full !text-14"
                placeholder="Input your campaign KPI metrics here"
                autoComplete="kpi"
                disabled={!isActive}
                isFocused={false}
                onChange={(e: any) => {
                  setData("kpi", e.target.value);
                  setError("kpi", { message: "" });
                }}
              />
            ) : (
              <p className="!text-14 px-0 py-6">
                {data.kpi.length > 0 ? data.kpi : "-"}
              </p>
            )}
          </div>
        </div>
        <RegenerateModal
          isOpen={isModalOpen}
          onClose={() => {
            fetchData();
            setIsEditing(false);
            setIsModalOpen(false);
          }}
          handleSubmit={submitForm}
        />
      </div>
    );
  },
);

export default ProjectObjective;
