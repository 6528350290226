import PrimaryButton from "@/Components/PrimaryButton";
import { ProjectProps } from "@/Types/projects";
import { useNavigate } from "react-router-dom";

const NotFoundCompetitor: React.FC<{ project: ProjectProps }> = ({
  project,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/${project.slug}/submission`);
  };
  return (
    <div className="flex flex-row gap-55 items-center justify-center min-h-[50vh]">
      <div className="my-6">
        <img
          src="/assets/img/ainstein-notfound.png"
          alt="Confused Character"
          className="w-48 h-auto mx-auto"
        />
      </div>
      <div className="flex-col gap-30">
        <div className="mb-6">
          <h1 className="text-24 font-semibold">No result found...</h1>
          <p className="text-gray-400 py-20">
            We couldn't find any results for Competitor Analysis.
            <br />
            You can input or update the Competitor Information in the submission
            section.
          </p>
        </div>

        <PrimaryButton
          className="text-18 px-30 font-semibold py-10 border-1 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
          onClick={handleClick}
        >
          Update Competitor Information
        </PrimaryButton>
      </div>
    </div>
  );
};

export default NotFoundCompetitor;
