import { useQuery } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import { ProjectProps } from "@/Types/projects";

const fetchProjects = async (projectSlug: string): Promise<ProjectProps> => {
  try {
    const response = await fetcher.get("/" + projectSlug + "/validate");
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response.data.message || "Failed to fetch projects data",
    );
  }
};

const useValidateProject = (projectSlug: string) => {
  return useQuery<ProjectProps, Error>({
    queryKey: ["projects", projectSlug],
    queryFn: () => fetchProjects(projectSlug),
    refetchOnMount: false,
  });
};

export default useValidateProject;
