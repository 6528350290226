import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import ModalCenter from "@/Components/Modals/Center";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import TextInput from "@/Components/TextInput";
import TextAreaInput from "@/Components/TextAreaInput";
import PrimaryButton from "@/Components/PrimaryButton";
import RequestAccessSuccessToast from "@/Components/Toast/RequestAccessSuccess";
import UserHasApprovedToast from "@/Components/Toast/UserHasApproved";
import { fetcher } from "@/Services/axios";

interface IFormRequest {
  email: string;
  password: string;
}

interface FormData {
  email: string;
  password: string;
  reason: string;
}

const RequestAccess: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  formRequest: IFormRequest;
}> = ({ isOpen, onClose, formRequest }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm<FormData>({
    defaultValues: {
      email: formRequest.email,
      password: formRequest.password,
      reason: "",
    },
  });

  const [disableButton, setDisableButton] = useState(false);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      setDisableButton(true);
      const response = await fetcher.post("/request-access", data);
      reset();
      handleClose();
      if (response.data.status === "approved") {
        toast.custom((t) => <UserHasApprovedToast t={t} />);
      } else {
        toast.custom((t) => <RequestAccessSuccessToast t={t} />);
      }
      
    } catch (err) {
      setDisableButton(false);
      if (axios.isAxiosError(err) && err.response?.data?.errors?.email) {
        setValue("email", err.response.data.errors.email[0]);
      }
    }
  };

  const handleClose = () => {
    setDisableButton(false);
    reset();
    onClose();
  };

  useEffect(() => {
    setValue("email", formRequest.email);
    setValue("password", formRequest.password);
  }, [formRequest, setValue]);

  return (
    <ModalCenter isOpen={isOpen} onClose={handleClose}>
      <div className={`relative w-full max-w-md min-w-486 md:min-w-[100vw]`}>
        <form
          className="w-full rounded-10 relative py-50 px-40 md:py-50 md:px-30"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <h1 className="font-medium text-25 text-black leading-30 mb-10">
              Request Access
            </h1>
          </div>
          <div className="mb-20">
            <InputLabel
              htmlFor="email"
              value="Email"
              className="!text-black-redx"
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInput
                  id="email_request"
                  type="email"
                  disabled={true}
                  {...field}
                  light={true}
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  className="mt-1 block w-full"
                  placeholder="Input your RedComm email here"
                />
              )}
            />
            {errors.email && (
              <InputError message={errors.email.message} className="mt-2" />
            )}
          </div>
          <div className="">
            <InputLabel
              htmlFor="reason"
              value="Why do you need access to Ainstein?"
              className="!text-black-redx"
            />
            <Controller
              name="reason"
              control={control}
              rules={{ required: "Reason is required" }}
              render={({ field }) => (
                <TextAreaInput
                  id="reason_request"
                  {...field}
                  rows={3}
                  light={true}
                  containerClassName="border-b-2 border-soft-purplestroke-redx text-black-redx"
                  className="mt-1 block w-full"
                  placeholder="Input your reason here"
                />
              )}
            />
            {errors.reason && (
              <InputError message={errors.reason.message} className="mt-2" />
            )}
          </div>
          <div className="flex justify-end items-center mt-30 gap-x-10">
            <PrimaryButton
              className="text-12 ms-4 px-20 py-10 bg-indigo-800 hover:bg-indigo-700 active:bg-indigo-700"
              disabled={isSubmitting || !watch("reason") || disableButton}
            >
              Send Request
            </PrimaryButton>
          </div>
        </form>
      </div>
    </ModalCenter>
  );
};

export default RequestAccess;
