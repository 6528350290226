import { Dispatch } from "react";
import { handleProgress } from "@/Utils/dispatcher";
import { ProjectAction } from "@/Context/actions/projectActions";
import { SubsectionDataType } from "@/Types/projects";
import {
  setAudienceArchetypeData,
  setAudienceArchetypeLoading,
} from "@/Context/actions/audience_archetype";
import { Archetype } from "@/Types/audience-archetype";
import { fetcher } from "@/Services/axios";

export const handleGenerateAudienceArchetype =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, regenerate_prompt: string | null = null) => {
    let interval: any;
    try {
      dispatch(
        setAudienceArchetypeLoading({
          status: true,
          progress: 0,
        }),
      );
      interval = handleProgress((progress: number) =>
        dispatch(
          setAudienceArchetypeLoading({
            status: true,
            progress,
          }),
        ),
      );

      const { data } = await fetcher.post(
        `/${project_slug}/audience-archetype/generate`,
        {
          generate_more: regenerate_prompt,
        },
      );

      setTimeout(() => {
        dispatch(
          setAudienceArchetypeLoading({
            status: true,
            progress: 100,
          }),
        );

        dispatch(
          setAudienceArchetypeLoading({
            status: false,
            progress: 100,
          }),
        );
        dispatch(setAudienceArchetypeData(data.data));
        clearInterval(interval);
      }, 5000);
    } catch (err) {
      clearInterval(interval);
      dispatch(
        setAudienceArchetypeLoading({
          status: false,
          progress: 0,
        }),
      );
    }
  };

export const handleSetAudienceArchetypeData =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setAudienceArchetypeData(data));
  };
