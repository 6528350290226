import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TypeIt from "typeit-react";
import toast from "react-hot-toast";

import validation from "@/Helpers/validation";
import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import Redhub from "@/Components/Icon/Redhub";
import Cookies from "js-cookie";
import PasswordInput from "@/Components/PasswordInput";
import RequestAccessModal from "@/Components/Modals/RequestAccess";
import RequestAccessToast from "@/Components/Toast/RequestAccess";
import { LoginFormValues } from "@/Types/login_page/login";
import useLogin from "@/Hooks/react-query/useLogin";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

export default function Login({
  status,
}: {
  status?: string;
  canResetPassword: boolean;
}) {
  const {
    mutate,
    data: dataLogin,
    isPending,
    isSuccess,
    isError,
    error,
  } = useLogin();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const forgotPasswordLink = import.meta.env.VITE_FORGOT_URL;

  const {
    handleSubmit,
    setError,
    reset,
    control,
    formState: { errors },
    getValues,
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: "",
      password: "",
      remember: false,
      no_access: null,
    },
  });
  const [formRequest, setFormRequest] = useState({ email: "", password: "" });

  useEffect(() => {
    reset({ password: "" });
  }, [reset]);

  useEffect(() => {
    if (errors.no_access) {
      toast.custom((t) => (
        <RequestAccessToast
          handleClick={openRequestAccess}
          t={t}
        />
      ));
    }
  }, [errors]);

  const openRequestAccess = () => {
    toast.remove();
    updateFormRequest(() => openModal());
  };

  const updateFormRequest = (callback: () => void) => {
    setFormRequest({
      email: getValues("email") || "",
      password: getValues("password") || "",
    });

    callback();
  };

  const updateFormField = async (
    field: "email" | "password",
    value: string,
  ) => {
    const validationRules = {
      email: () =>
        !_.isEmpty(value)
          ? validation.isEmail(value)
            ? ""
            : "Invalid email address!"
          : "The email field is required",
      password: () =>
        !_.isEmpty(value)
          ? validation.isPassword(value)
            ? ""
            : "Invalid password!"
          : "The password field is required",
    };
    const error = validationRules[field];
    if (error) {
      setError(field, { type: "manual", message: error() });
    }
  };

  const onSubmit = async (data: LoginFormValues) => {
    toast.remove();
    updateFormField("email", data.email);
    updateFormField("password", data.password);

    const validationRules = {
      email: () => validation.isEmail(data.email),
      password: () => validation.isPassword(data.password),
    };
    const emailValid = validationRules["email"];
    const passwordValid = validationRules["password"];

    if (emailValid() && passwordValid()) {
      await mutate(
        { email: data.email, password: data.password },
        {
          onSuccess: () => {
            navigate("/projects");
          },
          onError: (error) => {
            if (error.message.includes("email")) {
              setError("email", {
                type: "manual",
                message: "Invalid email address!",
              });
            }

            if (error.message.includes("password")) {
              setError("password", {
                type: "manual",
                message: "Invalid password!",
              });
            }

            if (error.message.includes("approved")) {
              setFormRequest({
                email: data.email,
                password: data.password,
              });
              toast.custom((t) => (
                <RequestAccessToast
                  handleClick={openRequestAccess}
                  t={t}
                />
              ));
            }
          },
        },
      );
    }
  };

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      navigate("/projects");
    } else {
      queryClient.removeQueries();
    }
  }, []);

  return (
    <GuestLayout>
      <Helmet>
        <title>Login - Ainstein</title>
      </Helmet>
      {status && (
        <div className='mb-4 font-medium text-sm text-green-600'>{status}</div>
      )}
      <TypeIt
        options={{
          loop: true,
        }}
        getBeforeInit={(instance) => {
          instance
            .type("Meet your<br/><b>AI marketing brain</b>")
            .pause(750)
            .delete(40)
            .pause(500)
            .type("24/7<br/><b>Agency Assistant</b>")
            .pause(750)
            .delete()
            .pause(500)
            .type("The more you use it,<br/><b>the smarter it gets</b>")
            .pause(750)
            .delete()
            .pause(500)
            .type("Let’s help you<br/><b>build that proposal</b>")
            .pause(750)
            .delete()
            .pause(500)
            .type("Let’s make your client<br/><b>love you more</b>")
            .pause(750)
            .delete()
            .pause(500);
          return instance;
        }}
        className='relative w-1/2 text-50 text-white h-auto md:hidden'
      />
      <form
        className='lg:min-w-538 md:max-w-xs py-60 px-73 md:py-50 md:px-30 rounded-20 relative bg-white01 bg-clip-padding backdrop-filter backdrop-blur-none bg-opacity-30'
        onSubmit={handleSubmit(onSubmit)}>
        <div>
          <h1 className='font-medium text-20 text-white leading-30 mb-10'>
            Get ready to explore AInstein!
          </h1>
        </div>
        <div className='mb-20'>
          <InputLabel
            htmlFor='email'
            value='Email'
          />
          <Controller
            name='email'
            control={control}
            render={({ field }) => (
              <TextInput
                id='email'
                type='text'
                light={true}
                containerClassName='border-b-2 border-white text-white'
                className='mt-1 block w-full'
                placeholder='Input your RedComm email here'
                autoComplete='username'
                isFocused={true}
                {...field}
                onChange={(e) => {
                  toast.remove();
                  field.onChange(e);
                }}
              />
            )}
          />
          <InputError
            message={errors.email?.message}
            light={true}
            className='mt-2'
          />
        </div>
        <div className=''>
          <InputLabel
            htmlFor='password'
            value='Password'
          />
          <Controller
            name='password'
            control={control}
            render={({ field }) => (
              <PasswordInput
                id='password'
                light={true}
                containerClassName='border-b-2 border-white text-white'
                className='mt-1 block w-full'
                placeholder='Input your password here'
                autoComplete='current-password'
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  toast.remove();
                  field.onChange(e);
                }}
              />
            )}
          />
          <InputError
            message={errors.password?.message}
            light={true}
            className='mt-2'
          />
        </div>
        <div className='text-right text-10 font-semibold md:text-12 md:py-10 mb-30 mt-5 text-white'>
          <a href={forgotPasswordLink}> Forgot password? </a>
        </div>
        <div className='flex items-center mt-4'>
          <PrimaryButton
            className='text-12 ms-4 w-full py-10 bg-blue-redx hover:bg-indigo-700 active:bg-indigo-700'
            disabled={isPending}>
            Login
          </PrimaryButton>
        </div>
      </form>
      <RequestAccessModal
        formRequest={formRequest}
        isOpen={isOpen}
        onClose={closeModal}
      />
    </GuestLayout>
  );
}
