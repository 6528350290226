import useUser from "@/Hooks/react-query/useUser";
import UpdateFormModal from "@/Pages/Projects/Partials/UpdateFormModal.tsx";
import { fetcher } from "@/Services/axios";
import { User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import { Icon } from "@iconify/react";
import React, { useState } from "react";

const ProjectMembers: React.FC<{
  project: ProjectProps;
  initialUsers: User[];
}> = ({ project, initialUsers }) => {
  const { data: user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectProps>();
  const closeModal = () => setIsOpen(false);
  const appUrl = new URL(window.location.href);
  const baseUrl = appUrl.origin;

  const handleClickAddMember = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const response = await fetcher.get(`/${project.slug}`);
    setSelectedProject(response.data);
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      <div className="flex -space-x-8 rtl:space-x-reverse">
        {project.owner && (
          <img
            title={`${project.owner.name} (${project.owner.email})`}
            className="w-32 h-32 border-2 border-white rounded-full"
            src={project.owner.avatar_url}
            alt=""
          />
        )}
        {project.users.map((u, i) => (
          <img
            key={i}
            title={`${u.name ?? "Noname"} (${u.email})`}
            className="w-32 h-32 border-2 border-white rounded-full"
            src={u.avatar_url}
            alt=""
          />
        ))}
        {project.owner.id === user.id && (
          <button
            className="flex items-center justify-center w-32 h-32 text-blue-redx bg-soft-purple-redx border-2 border-white rounded-full"
            onClick={handleClickAddMember}
          >
            <Icon icon="uil:plus" className="w-24 h-24" />
          </button>
        )}
      </div>
      <UpdateFormModal
        isOpen={isOpen}
        onClose={closeModal}
        initialUsers={initialUsers}
        project={selectedProject ?? project}
        appUrl={baseUrl}
      />
    </React.Fragment>
  );
};

export default ProjectMembers;
