import List from "@/Pages/Projects/List";

const ProjectRoutes = [
  {
    path: "/projects",
    element: (
      <List projects={[]} maxGenerateLimit={2} />
    ) as React.ReactElement,
  },
];

export default ProjectRoutes;
