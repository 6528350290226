import toast from "react-hot-toast";

const GenerateNotification: React.FC<{ t: any }> = ({ t }) => {
  return (
    <div
      className="inline-flex items-center py-10 px-20 mb-4 text-10 text-white bg-success-redx rounded-lg"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: "opacity 100ms ease-in-out",
      }}
      onClick={() => toast.remove()}
    >
      <p className="mr-10">
        Notification successfully set. We will notify you when the generate
        process is complete.
      </p>
      X
    </div>
  );
};

export default GenerateNotification;
