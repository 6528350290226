import React, { TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  isFocused?: boolean;
  rightElement?: React.ReactNode;
  error?: string;
  containerClassName?: string;
  light?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  className = "",
  containerClassName = "",
  isFocused = false,
  error = "",
  placeholder = "",
  light = false,
  rightElement = null,
  ...props
}) => {
  const placeholderClass = light
    ? "placeholder-lightplaceholder-redx"
    : "placeholder-placeholder-redx";

  return (
    <>
      <div
        className={
          (error ? "!border-red-500 " : "") +
          "bg-transparent flex w-full items-center overflow-hidden " +
          containerClassName
        }
      >
        <textarea
          className={
            `px-0 py-2 bg-transparent rounded-md shadow-sm text-12 !border-transparent !focus:border-transparent !focus:ring-0 ${placeholderClass} ` +
            className
          }
          placeholder={placeholder}
          {...props}
        />
        {rightElement}
      </div>
      {error && <p className="text-red-500 text-12 mt-2">{error}</p>}
    </>
  );
};

export default TextArea;
