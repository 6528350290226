import React from "react";

import { Icon } from "@iconify/react";

const DownloadButton: React.FC<{
  handleDownload: () => void;
}> = ({ handleDownload }) => {
  return (
    <button
      className="text-15 font-semibold inline-flex text-blue-redx gap-x-4"
      onClick={handleDownload}
    >
      Download
      <Icon icon="lucide:download" className="w-20 h-20" />
    </button>
  );
};

export default DownloadButton;
