import React, { Fragment, useEffect, useState } from "react";
import { Icon } from "@iconify/react";

import EditButton from "@/Components/Projects/EditButton";

const SectionCard: React.FC<{
  title: string;
  isFetching?: boolean;
  cols: 1 | 2;
  children: (props: {
    isEditing: boolean;
    gridColumnClass: string;
  }) => React.ReactNode;
  initialEditState?: boolean;
  onCancelEdit: () => void;
  onSubmitEdit: () => void;
  disabledSave?: boolean;
}> = ({
  isFetching = false,
  title,
  cols = 1,
  children,
  initialEditState = false,
  onCancelEdit,
  onSubmitEdit,
  disabledSave,
}) => {
  const [isEditing, setIsEditing] = useState(initialEditState);
  const toggleEditing = () => setIsEditing(!isEditing);
  const gridColumnClass = cols === 1 ? "grid-cols-1" : "grid-cols-2";

  const handleCancelEdit = () => {
    onCancelEdit();
    toggleEditing();
  };

  const handleSubmitEdit = () => {
    onSubmitEdit();
    toggleEditing();
  };

  useEffect(() => {
    setIsEditing(initialEditState);
  }, [initialEditState]);

  return (
    <div className="flex flex-col w-full gap-y-8 text-black-redx ">
      <h3 className="text-15 font-bold border-b-1 pb-4 border-stroke-redx">
        {title}
      </h3>
      <div className={`border-stroke-redx ${!isEditing ? "border-b-1" : ""}`}>
        {children({ isEditing, gridColumnClass })}
      </div>
      <div className={`flex w-full justify-end items-center gap-x-15`}>
        {isFetching ? (
          <div className="w-65 h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14" />
        ) : isEditing ? (
          <Fragment>
            <button
              className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
              onClick={handleCancelEdit}
            >
              <span className="text-15 font-semibold">Cancel</span>
              <Icon icon="lucide:x" className="w-20 h-20" />
            </button>
            <button
              className={`inline-flex items-center gap-x-4 ${
                disabledSave ? "text-gray-500" : "text-blue-redx"
              } cursor-pointer`}
              onClick={handleSubmitEdit}
              disabled={disabledSave}
            >
              <span className="text-15 font-semibold">Save</span>
              <Icon icon="lucide:save" className="w-20 h-20" />
            </button>
          </Fragment>
        ) : (
          <EditButton toggleEditing={toggleEditing} />
        )}
      </div>
    </div>
  );
};

export default SectionCard;
