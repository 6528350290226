import React, { useContext, useEffect } from "react";
import { useSidebarContext } from "@/Context/SidebarContext";
import { ProjectContext } from "@/Context/ProjectContext";
import { ProjectProps } from "@/Types/projects";
import MenuLink from "./MenuLink"; // Ensure this import is correct
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { saveSubInformation } from "@/Context/actions/projectActions";
import useDifferentArchetypes from "@/Hooks/react-query/audience-archetype/useDifferentArchetypes";

const Sidebar: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const { isSidebarOpen } = useSidebarContext();
  const [state, dispatch] = useContext(ProjectContext);
  const { data: differentArchetypes, refetch } =
    useDifferentArchetypes(project);
  const {
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchProductDescription,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
    totalCompetitors,
    isPending: isPendingHistory,
  } = useHistoryStatus(project, state.isEnabledHistoryStatus);

  useEffect(() => dispatch(saveSubInformation(``)), []);

  useEffect(() => {
    refetch();
  }, [
    isMarketResearchDemand,
    isMarketResearchTrend,
    isProductResearchMainFeature,
    isProductResearchMarcommHighlight,
    isProductResearchProductDescription,
    isAudienceArchetypeComplete,
    isKeyInsightsComplete,
    isCompetitorAnalysisComplete,
    isOpportunityAmongCompetitionComplete,
    isChallengeAndTaskOfTheBrand,
    isOnePageStrategy,
    isKeyTouchPoint,
    isTargetAudienceAudienceInsight,
    isTargetAudienceBehavioralTrend,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudiencePerceptionAnalysis,
  ]);

  return (
    <aside
      className={`fixed top-0 left-0 bottom-0 z-30 h-full shadow-xl transition-transform ${
        isSidebarOpen ? "block" : "hidden"
      }`}
      aria-label='Sidebar'>
      <div className='h-full w-full max-h-screen overflow-y-auto bg-darkblue-redx flex flex-col justify-between py-60'>
        <ul className='h-full flex flex-col gap-y-8 overflow-y-auto px-30'>
          <div className='py-5 text-14 text-gray-200 border-b-2 border-stroke-redx'>
            Strategy
          </div>
          {/* Ensure the paths are correctly defined in your routing configuration */}
          <li>
            <MenuLink
              to={`/${project.slug}/submission`}
              icon='lucide:clipboard-list'
              disabled={false}>
              Submission
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/product-research`}
              icon='lucide:package-search'
              isLoading={
                !isPendingHistory &&
                (isProductResearchProductDescription.isQuerying ||
                  isProductResearchMainFeature.isQuerying ||
                  isProductResearchMarcommHighlight.isQuerying)
              }
              disabled={
                !isPendingHistory &&
                !isProductResearchProductDescription.status &&
                !isProductResearchMainFeature.status &&
                !isProductResearchMarcommHighlight.status
              }>
              Product Research
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/market-research`}
              icon='lucide:file-line-chart'
              isLoading={
                !isPendingHistory &&
                (isMarketResearchDemand.isQuerying ||
                  isMarketResearchTrend.isQuerying)
              }
              disabled={
                !isPendingHistory &&
                !isMarketResearchDemand.status &&
                !isMarketResearchTrend.status
              }>
              Market Research: Industry
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/target-audience`}
              icon='lucide:users-round'
              isLoading={
                !isPendingHistory &&
                (isTargetAudienceAudienceInsight.isQuerying ||
                  isTargetAudienceBehavioralTrend.isQuerying ||
                  isTargetAudienceMediaConsumptionPattern.isQuerying ||
                  isTargetAudiencePerceptionAnalysis.isQuerying)
              }
              disabled={
                !isPendingHistory &&
                !isTargetAudienceAudienceInsight.status &&
                !isTargetAudienceBehavioralTrend.status &&
                !isTargetAudienceMediaConsumptionPattern.status &&
                !isTargetAudiencePerceptionAnalysis.status
              }>
              Target Audience
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/audience-archetype`}
              icon='lucide:book-user'
              isLoading={
                !isPendingHistory && isAudienceArchetypeComplete.isQuerying
              }
              disabled={
                !isPendingHistory && !isAudienceArchetypeComplete.status
              }>
              Audience Archetype
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/key-insight`}
              icon='lucide:lightbulb'
              isLoading={!isPendingHistory && isKeyInsightsComplete.isQuerying}
              disabled={
                !isPendingHistory &&
                (!isKeyInsightsComplete.status ||
                  differentArchetypes?.is_different_archetype.key_insight)
              }>
              Key Insight
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/challenge-and-communication-task`}
              icon='lucide:puzzle'
              isLoading={
                !isPendingHistory && isChallengeAndTaskOfTheBrand.isQuerying
              }
              disabled={
                !isPendingHistory &&
                (!isChallengeAndTaskOfTheBrand.status ||
                  differentArchetypes?.is_different_archetype.challenges ||
                  !differentArchetypes?.is_selected_content.key_insight)
              }>
              Challenge & Communication Task
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/competitor-analysis`}
              icon='lucide:sword'
              isLoading={
                !isPendingHistory && isCompetitorAnalysisComplete.isQuerying
              }
              disabled={
                (!isPendingHistory &&
                  !isProductResearchProductDescription.status &&
                  !isProductResearchMainFeature.status &&
                  !isProductResearchMarcommHighlight.status) ||
                totalCompetitors === 0
              }>
              Competitor Analysis
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/opportunity-among-competition`}
              icon='lucide:split'
              isLoading={
                !isPendingHistory &&
                isOpportunityAmongCompetitionComplete.isQuerying
              }
              disabled={
                !isPendingHistory &&
                (!isOpportunityAmongCompetitionComplete.status ||
                  differentArchetypes?.is_different_archetype.opportunities)
              }>
              Opportunity Among Competition
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/one-page-strategy`}
              icon='lucide:goal'
              isLoading={!isPendingHistory && isOnePageStrategy.isQuerying}
              disabled={
                !isPendingHistory &&
                (!isOnePageStrategy.status ||
                  differentArchetypes?.is_different_archetype.one_pages ||
                  !differentArchetypes?.is_selected_content.key_insight ||
                  !differentArchetypes?.is_selected_content.challenges ||
                  !differentArchetypes?.is_selected_content.opportunities)
              }>
              One-Page Strategy
            </MenuLink>
          </li>
          <li>
            <MenuLink
              to={`/${project.slug}/key-touch-point`}
              icon='lucide:key-square'
              isLoading={!isPendingHistory && isKeyTouchPoint.isQuerying}
              disabled={
                !isPendingHistory &&
                (!isKeyTouchPoint.status ||
                  differentArchetypes?.is_different_archetype.key_touch_points)
              }>
              Key Touch Point
            </MenuLink>
          </li>
        </ul>
      </div>
      <div className='relative'>
        <div className='flex flex-col justify-between w-full px-30 gap-y-10'>
          <div className='flex flex-col py-40 gap-y-8 items-start space-y-2 border-t-2 border-stroke-redx font-medium overflow-y-auto overflow-custom w-full'>
            <div className='inline-flex items-center justify-center text-14 text-gray-200 py-8 border-soft-purple-redx border-2 w-full rounded-8 cursor-not-allowed'>
              Duplicate Project
              <span className='inline-flex items-center bg-soft-purple-redx px-4 py-2 ml-8 text-center uppercase text-blue-redx font-bold text-8 rounded-4'>
                Coming Soon
              </span>
            </div>
            <div className='inline-flex justify-center text-14 text-gray-200 py-8 border-soft-purple-redx border-2 w-full rounded-8'>
              View History
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
