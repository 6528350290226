import { User } from "@/Types";

export const mockUserData: User = {
  roles: ["Admin", "Editor"],
  id: 101,
  name: "User 1",
  email: "user1@example.com",
  avatar: "user1_avatar.png",
  avatar_url: "https://example.com/avatars/user1_avatar.png",
  email_verified_at: "2024-07-01T12:00:00Z",
  created_at: "2024-01-01T00:00:00Z",
  updated_at: "2024-07-01T12:00:00Z",
  whatsapp_number: "+1234567890",
  status: "Active",
};
