export const handleProgress = (dispatch: (progress: number) => void) => {
  let current_progress = 0;
  let step = 0.5;
  let progress = 0;
  const interval = setInterval(() => {
    current_progress += step;
    progress =
      Math.round((Math.atan(current_progress) / (Math.PI / 2)) * 100 * 1000) /
      1000;
    progress = Math.round(progress * 10) / 10;
    if (progress >= 100) {
      clearInterval(interval);
    } else if (progress >= 70) {
      step = 0.2;
    }
    if (dispatch) {
      dispatch(progress);
    }
  }, 1000);
  return interval;
};

type ProgressPortionType = {
  progress: number;
  isQuerying: boolean;
  isContentAvailable: boolean;
};

export const progressPortion = ({
  progress,
  isQuerying,
  isContentAvailable,
}: ProgressPortionType): number => {
  let percentage = 0;

  if (progress > 0) {
    percentage += progress / 70;
  }

  if (isQuerying) {
    percentage -= 20;
  }

  if (isContentAvailable) {
    percentage += 10;
  }

  return progress;
};
