import { useState, useRef } from "react";

const useTag = (initialTags: string[] = []) => {
  const [tags, setTags] = useState<string[]>(initialTags);
  const tagInput = useRef<HTMLInputElement>(null);

  const addTag = (tag: string) => {
    if (!tags.find((t) => t.toLowerCase() === tag.toLowerCase())) {
      setTags([...tags, tag]);
    }
  };

  const removeTag = (i: number) => {
    setTags(tags.filter((_, index) => index !== i));
  };

  const inputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const val = (e.target as HTMLInputElement).value;
    if ((e.key === " " && val) || (e.key === "Enter" && val)) {
      // addTag(val.trim());
      // e.preventDefault();
      // if (tagInput.current) {
      //     tagInput.current.value = '';
      // }
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  const clearTags = () => {
    setTags([]);
  };

  return {
    tags,
    tagInput,
    addTag,
    removeTag,
    inputKeyDown,
    clearTags,
  };
};

export default useTag;
