import { useMutation } from "@tanstack/react-query";
import { fetcher } from "@/Services/axios";
import Cookies from "js-cookie";

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  try {
    const response = await fetcher.post("/login", {
      email,
      password,
      remember: false,
      no_access: null,
    });

    const { access_token } = response.data;

    // Set the access_token cookie
    Cookies.set("access_token", access_token, { expires: 1 }); // The cookie will expire in 1 day

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

const useLogin = () => {
  return useMutation<any, Error, { email: string; password: string }>({
    mutationFn: login,
  });
};

export default useLogin;
