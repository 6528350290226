import React, { useContext, useEffect, useState, Fragment } from "react";
import { ProjectProps } from "@/Types/projects";
import { ProjectContext } from "@/Context/ProjectContext";
import {
  saveRouteNext,
  saveRoutePrev,
  saveInformation,
} from "@/Context/actions/projectActions";
import { Tab, TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import GenerateLoading from "@/Components/GenerateLoading";

import { SectionList } from "@/Types/tabs";
import CardAudienceInsights from "./Partials/CardAudienceInsights";
import CardPerceptionAnalysis from "./Partials/CardPerceptionAnalysis";
import CardBehavioralTrends from "./Partials/CardBehavioralTrends";
import CardMediaConsumptionPatterns from "./Partials/CardMediaConsumptionPatterns";
import { historiesFilter } from "@/Helpers/filter";
import useHistoryLatest from "@/Hooks/react-query/useHistoryLatest";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { useGenerate } from "@/Context/hooks/useGenerate";
import toast from "react-hot-toast";
import ErrorLLM from "@/Components/Toast/ErrorLLM";

const Index: React.FC<{ project: ProjectProps }> = ({ project }) => {
  const navigate = useNavigate();
  const [_, dispatch] = useContext(ProjectContext);
  const [isEditing, setIsEditing] = useState(false);
  const {
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    isPending: IsPendingTargetAudience,
  } = useHistoryStatus(project);
  const [isGenerateAudienceInsight, setIsGenerateAudienceInsight] =
    useState(false);
  const [isGeneratePerceptionAnalysis, setIsGeneratePerceptionAnalysis] =
    useState(false);
  const [isGenerateBehavioralTrends, setIsGenerateBehavioralTrends] =
    useState(false);
  const [
    isGenerateMediaConsumptionPatterns,
    setIsGenerateMediaConsumptionPatterns,
  ] = useState(false);
  const sectionList: SectionList[] = [
    {
      title: "Audience Insights",
      value: "audience_insight",
      section: "target_audience",
    },
    {
      title: "Perception Analysis",
      value: "perception_analysis",
      section: "target_audience",
    },
    {
      title: "Behavioral Trends",
      value: "behavioral_trends",
      section: "target_audience",
    },
    {
      title: "Media Consumption Patterns",
      value: "media_consumption_patterns",
      section: "target_audience",
    },
  ];

  const {
    handleGenerateTargetAudienceAudienceInsight,
    handleGenerateTargetAudienceBehavioralTrend,
    handleGenerateTargetAudienceMediaConsumptionPatterns,
    handleGenerateTargetAudiencePerceptionAnalysis,

    handleSetTargetAudienceAudienceInsights,
    handleSetTargetAudiencePerceptionAnalysis,
    handleSetTargetAudienceBehavioralTrends,
    handleSetTargetAudienceMediaConsumptionPatterns,
  } = useGenerate(dispatch);

  const toggleIsEditing = () => setIsEditing(!isEditing);

  const { data, isLoading, refetch } = useHistoryLatest(project);

  useEffect(() => {
    const targetAudienceAudienceInsight = historiesFilter({
      data,
      section: "target_audience",
      subSection: "audience_insight",
    });
    const targetAudiencePerceptionAnalysis = historiesFilter({
      data,
      section: "target_audience",
      subSection: "perception_analysis",
    });
    const targetAudienceBehavioralTrends = historiesFilter({
      data,
      section: "target_audience",
      subSection: "behavioral_trends",
    });
    const targetAudienceMediaConsumptionPatterns = historiesFilter({
      data,
      section: "target_audience",
      subSection: "media_consumption_patterns",
    });

    if (targetAudienceAudienceInsight?.history_id) {
      handleSetTargetAudienceAudienceInsights()(targetAudienceAudienceInsight);
    } else {
      if (
        !isLoading &&
        !IsPendingTargetAudience &&
        !isTargetAudienceAudienceInsight.status
      ) {
        setIsGenerateAudienceInsight(true);
        !isTargetAudienceAudienceInsight.isQuerying &&
          handleGenerateTargetAudienceAudienceInsight()(project.slug);
      }
    }

    if (isTargetAudienceAudienceInsight.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (targetAudiencePerceptionAnalysis?.history_id) {
      handleSetTargetAudiencePerceptionAnalysis()(
        targetAudiencePerceptionAnalysis,
      );
    } else {
      if (
        !isLoading &&
        !IsPendingTargetAudience &&
        !isTargetAudiencePerceptionAnalysis.status
      ) {
        setIsGeneratePerceptionAnalysis(true);
        !isTargetAudiencePerceptionAnalysis.isQuerying &&
          handleGenerateTargetAudiencePerceptionAnalysis()(project.slug);
      }
    }

    if (isTargetAudiencePerceptionAnalysis.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (targetAudienceBehavioralTrends?.history_id) {
      handleSetTargetAudienceBehavioralTrends()(targetAudienceBehavioralTrends);
    } else {
      if (
        !isLoading &&
        !IsPendingTargetAudience &&
        !isTargetAudienceBehavioralTrend.status
      ) {
        setIsGenerateBehavioralTrends(true);
        !isTargetAudienceBehavioralTrend.isQuerying &&
          handleGenerateTargetAudienceBehavioralTrend()(project.slug);
      }
    }

    if (isTargetAudienceBehavioralTrend.isError) {
      toast.custom((t) => <ErrorLLM t={t} />);
    }

    if (targetAudienceMediaConsumptionPatterns?.history_id) {
      handleSetTargetAudienceMediaConsumptionPatterns()(
        targetAudienceMediaConsumptionPatterns,
      );
    } else {
      if (
        !isLoading &&
        !IsPendingTargetAudience &&
        !isTargetAudienceMediaConsumptionPattern.status
      ) {
        setIsGenerateMediaConsumptionPatterns(true);
        !isTargetAudienceMediaConsumptionPattern.isQuerying &&
          handleGenerateTargetAudienceMediaConsumptionPatterns()(project.slug);
      }

      if (isTargetAudienceMediaConsumptionPattern.isError) {
        toast.custom((t) => <ErrorLLM t={t} />);
      }
    }

    if (
      !IsPendingTargetAudience &&
      (isTargetAudienceAudienceInsight.isQuerying ||
        isTargetAudienceMediaConsumptionPattern.isQuerying ||
        isTargetAudiencePerceptionAnalysis.isQuerying ||
        isTargetAudienceBehavioralTrend.isQuerying)
    ) {
      isTargetAudienceAudienceInsight.isQuerying &&
        setIsGenerateAudienceInsight(true);
      isTargetAudienceMediaConsumptionPattern.isQuerying &&
        setIsGenerateMediaConsumptionPatterns(true);
      isTargetAudiencePerceptionAnalysis.isQuerying &&
        setIsGeneratePerceptionAnalysis(true);
      isTargetAudienceBehavioralTrend.isQuerying &&
        setIsGenerateBehavioralTrends(true);
    }
  }, [
    data,
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
    IsPendingTargetAudience,
  ]);

  useEffect(() => {
    dispatch(
      saveRouteNext({
        label: "Generate Audience Archetype",
        isActive: true,
        isGenerate: true,
        onClick: () => {
          navigate(`/${project.slug}/audience-archetype`);
        },
      }),
    );
    dispatch(
      saveRoutePrev({
        label: "Back",
        isActive: true,
        onClick: () => {
          navigate(`/${project.slug}/market-research`);
        },
      }),
    );
    dispatch(saveInformation(""));
  }, []);

  useEffect(() => {
    if (
      (isTargetAudienceAudienceInsight.status &&
        !isTargetAudienceAudienceInsight.isQuerying) ||
      (isTargetAudienceMediaConsumptionPattern.status &&
        !isTargetAudienceMediaConsumptionPattern.isQuerying) ||
      (isTargetAudienceBehavioralTrend.status &&
        !isTargetAudienceBehavioralTrend.isQuerying) ||
      (isTargetAudiencePerceptionAnalysis.status &&
        !isTargetAudiencePerceptionAnalysis.isQuerying)
    ) {
      refetch();
    }
  }, [
    isTargetAudienceAudienceInsight,
    isTargetAudienceMediaConsumptionPattern,
    isTargetAudienceBehavioralTrend,
    isTargetAudiencePerceptionAnalysis,
  ]);

  return (
    <Fragment>
      <Helmet>
        <title>Target Audience</title>
      </Helmet>
      <div className='w-full h-full'>
        <div className='w-full sticky top-56 z-20 bg-white py-16'>
          <h1 className='text-25 font-bold mb-8 leading-none'>
            Target Audience
          </h1>
          <span className='text-15 font-normal text-grey-redx'>
            Please review and edit this information, considering that the
            provided reference may not always be accurate.
          </span>
        </div>
        <TabGroup className='pb-80'>
          <TabList className={"flex w-full"}>
            {sectionList.map((section, index) => (
              <Tab
                disabled={isEditing}
                className='text-15 px-45 pb-10 border-b-1 text-black-redx w-1/3 data-[selected]:border-blue-redx data-[selected]:text-blue-redx data-[selected]:font-bold data-[selected]:border-b-3 data-[focus]:outline-0 data-[focus]:outline-transparent [&:has(:focus-visible)]:ring-0'
                key={index}>
                {section.title}
              </Tab>
            ))}
          </TabList>
          <TabPanels className={"py-24"}>
            <TabPanel>
              <CardAudienceInsights
                project={project}
                section={sectionList[0]}
                isEditing={isEditing}
                isLoading={isLoading}
                toggleIsEditing={toggleIsEditing}
                isGenerateAudienceInsight={isGenerateAudienceInsight}
              />
            </TabPanel>
            <TabPanel>
              <CardPerceptionAnalysis
                project={project}
                section={sectionList[1]}
                isEditing={isEditing}
                isLoading={isLoading}
                toggleIsEditing={toggleIsEditing}
                isGeneratePerceptionAnalysis={isGeneratePerceptionAnalysis}
              />
            </TabPanel>
            <TabPanel>
              <CardBehavioralTrends
                project={project}
                section={sectionList[2]}
                isEditing={isEditing}
                isLoading={isLoading}
                toggleIsEditing={toggleIsEditing}
                isGenerateBehavioralTrends={isGenerateBehavioralTrends}
              />
            </TabPanel>
            <TabPanel>
              <CardMediaConsumptionPatterns
                project={project}
                section={sectionList[3]}
                isEditing={isEditing}
                isLoading={isLoading}
                toggleIsEditing={toggleIsEditing}
                isGenerateMediaConsumptionPatterns={
                  isGenerateMediaConsumptionPatterns
                }
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </Fragment>
  );
};

export default Index;
