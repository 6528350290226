import { useEffect } from "react";
import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import { useForm, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { fetcher } from "@/Services/axios";
import toast from "react-hot-toast";

type FormData = {
  password: string;
};

export default function ConfirmPassword() {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Use Inertia's post method for form submission
      await fetcher.post("/confirm-password", data);
    } catch (error) {
      toast.error("Error confirm password!");
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Confirm Password</title>
      </Helmet>

      <div className="mb-4 text-sm text-gray-600">
        This is a secure area of the application. Please confirm your password
        before continuing.
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <InputLabel htmlFor="password" value="Password" />

          <TextInput
            id="password"
            type="password"
            className="mt-1 block w-full"
            {...register("password", { required: "Password is required" })}
          />

          <InputError message={errors.password?.message} className="mt-2" />
        </div>

        <div className="flex items-center justify-end mt-4">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Confirm
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
}
