import { PropsWithChildren, ReactNode, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Profile from "@/Components/TopMenu/Profile";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "@/Hooks/react-query/useUser";
import { Icon } from "@iconify/react";
import { useSidebarContext } from "@/Context/SidebarContext";
import markerSDK from "@marker.io/browser";

export default function Authenticated({
  children,
}: PropsWithChildren<{ header?: ReactNode }>) {
  const { data: user, isLoading } = useUser();
  const navigate = useNavigate();
  const { isSidebarOpen, closeSidebar, showSidebar } = useSidebarContext();
  const location = useLocation();

  useEffect(() => {
    // TODO: Will replace with openreplay
    const initializeMarker = async () => {
      try {
        // Load the Marker.io widget
        const widget = await markerSDK.loadWidget({
          // TODO: convert into ENV
          project:
            import.meta.env.VITE_MARKER_PROJECT_CODE ??
            "66d527ce6c3f5b2807cff0c3",
        });

        widget.setReporter({
          email: user?.email ?? "ai@ainstein.com",
          fullName: user?.name ?? "ainstein",
        });

        // Optional: Show the widget or configure further as needed
        widget.show();
      } catch (error) {
        console.error("Failed to load Marker.io widget:", error);
      }
    };

    initializeMarker();
  }, [user]);

  useEffect(() => {
    const removeOverflowHidden = () => {
      if (document.body.style.overflow === "hidden") {
        document.body.style.overflow = ""; // Remove the overflow hidden style
      }
    };
    removeOverflowHidden();

    const handlePopState = () => {
      removeOverflowHidden();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // TODO: Will replace with openreplay
    (function (
      c: any,
      l: Document,
      a: string,
      r: string,
      i: string,
      t?: HTMLScriptElement,
      y?: HTMLElement,
    ) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0] as HTMLElement;

      y?.parentNode?.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      import.meta.env.VITE_CLARITY_PROJECT_CODE,
    );
  }, []);

  const handleLogout = () => {
    // Remove the access_token cookie
    Cookies.remove("access_token");

    // Redirect the user to the login page or another appropriate page
    navigate("/login", { replace: true });
  };

  const handleBackToProject = () => {
    navigate("/projects", { replace: true });
  };

  const shouldShowMenuIcon = location.pathname !== "/projects";
  if (!isLoading) {
    return (
      <div className='w-full bg-white'>
        <header className='fixed z-40 top-0 left-0 bg-gradient-to-r from-[#00C2DD] via-[#330DCC] to-[#610DCC] py-10 px-30 flex w-full justify-between items-center'>
          <div className='inline-flex flex-row gap-10'>
            {shouldShowMenuIcon && (
              <Icon
                className='w-24 h-24 mr-20 text-white block lg:hidden cursor-pointer'
                icon='lucide:menu'
                onClick={() => {
                  isSidebarOpen ? closeSidebar() : showSidebar();
                }}
              />
            )}
            <img
              src={"/assets/img/AInstein.svg"}
              className='h-35 w-auto cursor-pointer'
              alt='AInstein.png'
              onClick={handleBackToProject}
            />
          </div>
          <Profile avatar={user.avatar_url} />
        </header>

        {!isLoading && user && <main>{children}</main>}
      </div>
    );
  }
  if (!user && !isLoading) {
    handleLogout();
  }
}
