import GenerateLoading from "@/Components/GenerateLoading";
import EditButton from "@/Components/Projects/EditButton";
import RegenerateButton from "@/Components/Projects/RegenerateButton";
import SourcesList from "@/Components/Projects/SourcesList";
import useRolesBadge from "@/Hooks/useRolesBadge";
import { ProjectProps, SourcesType } from "@/Types/projects";
import {
  TargetAudienceItem,
  PerceptionAnalysisProps,
} from "@/Types/target_audience";
import { isYourRolesCanEdit } from "@/Utils/helper";
import { Icon } from "@iconify/react/dist/iconify.js";
import React, { Fragment, useContext, useEffect, useState } from "react";
import MarkdownEditor from "@/Components/MarkdownEditor";
import { ProjectContext } from "@/Context/ProjectContext";
import { useGenerate } from "@/Context/hooks/useGenerate";
import { jsonToMarkdown, markdownToJson } from "../Utils/utils";
import TargetAudienceList from "./TargetAudienceList";
import { progressPortion } from "@/Utils/dispatcher";
import useUser from "@/Hooks/react-query/useUser";
import useHistoryStatus from "@/Hooks/react-query/useHistoryStatus";
import { useForm } from "react-hook-form";
import {
  AudiencePerceptionAnalysis,
  AudiencePerceptionAnalysisSchema,
} from "@/Types/target_audience/schema-audience-perception-analysis";
import { zodResolver } from "@hookform/resolvers/zod";
import useUpdateTargetAudience from "@/Hooks/react-query/target-audience/useUpdateTargetAudience";
import { SectionList } from "@/Types/tabs";

interface Props {
  project: ProjectProps;
  section: SectionList;
  isEditing: boolean;
  isLoading: boolean;
  isGeneratePerceptionAnalysis: boolean;
  toggleIsEditing: () => void;
}

const CardPerceptionAnalysis: React.FC<Props> = ({
  project,
  section,
  isEditing,
  isLoading,
  isGeneratePerceptionAnalysis,
  toggleIsEditing,
}) => {
  const [state, dispatch] = useContext(ProjectContext);
  const [isGenerate, setIsGenerate] = useState(false);
  const { data: user } = useUser();
  const maxGenerateLimit = project.max_generated_data;
  const { mutate: updateTargetAudience } = useUpdateTargetAudience();
  const { roles } = useRolesBadge(project, user);
  const [parsedData, setParsedData] = useState<PerceptionAnalysisProps | null>({
    brand_perception: [],
    sector_perception: [],
    product_category_perception: [],
  });
  const [brandPerception, setBrandPerception] = useState<TargetAudienceItem[]>(
    [],
  );
  const [sectorPerception, setSectorPerception] = useState<
    TargetAudienceItem[]
  >([]);
  const [productCategoryPerception, setProductCategoryPerception] = useState<
    TargetAudienceItem[]
  >([]);

  const {
    handleGenerateTargetAudiencePerceptionAnalysis,
    handleSetTargetAudiencePerceptionAnalysis,
  } = useGenerate(dispatch);

  const stateProps = state.targetAudience.perception_analysis;
  const status = stateProps.status;
  const progress = stateProps.progress;
  const data = stateProps.data;
  const content = data.content;
  const sources = data.sources;
  const total_regenerate: number = data.total_regenerate
    ? data.total_regenerate
    : 0;
  const { isTargetAudiencePerceptionAnalysis: isTargetAudience } =
    useHistoryStatus(project);

  const { reset, setValue, getValues } =
    useForm<AudiencePerceptionAnalysisSchema>({
      resolver: zodResolver(AudiencePerceptionAnalysis),
      defaultValues: {
        brand_perception: "",
        sector_perception: "",
        product_category_perception: "",
      },
      mode: "all",
    });

  const handleSetParsedData = () => {
    const stringContent = content;
    if (stringContent) {
      try {
        const parsedContent = JSON.parse(
          stringContent,
        ) as PerceptionAnalysisProps;
        setParsedData(parsedContent);
        setBrandPerception(parsedContent.brand_perception);
        setSectorPerception(parsedContent.sector_perception);
        setProductCategoryPerception(parsedContent.product_category_perception);

        reset({
          brand_perception: jsonToMarkdown(parsedContent.brand_perception),
          sector_perception: jsonToMarkdown(parsedContent.sector_perception),
          product_category_perception: jsonToMarkdown(
            parsedContent.product_category_perception,
          ),
        });
      } catch (error) {
        console.error(error, "error perception analysis");
        console.log(
          "error while parsing =>",
          stringContent,
          "perception analysis",
        );
      }
    }
  };

  const handleSaveData = () => {
    toggleIsEditing();

    const formattedContent: PerceptionAnalysisProps = {
      brand_perception: markdownToJson(getValues("brand_perception")),
      sector_perception: markdownToJson(getValues("sector_perception")),
      product_category_perception: markdownToJson(
        getValues("product_category_perception"),
      ),
    };

    updateTargetAudience(
      {
        payload: JSON.stringify(formattedContent),
        historyId: data.history_id,
        projectSlug: project.slug,
      },
      {
        onSuccess: (data) => {
          handleSetTargetAudiencePerceptionAnalysis()(data.data);
        },
      },
    );
  };

  const handleRegenerateButton = (prompt: string) => {
    setIsGenerate(true);
    handleGenerateTargetAudiencePerceptionAnalysis()(project.slug, prompt);
  };

  useEffect(() => {
    if (!isTargetAudience.isQuerying && parsedData?.brand_perception.length) {
      setIsGenerate(false);
    }
  }, [isTargetAudience.isQuerying, parsedData]);

  useEffect(() => {
    if (isGeneratePerceptionAnalysis) {
      setIsGenerate(true);
    }
  }, [isGeneratePerceptionAnalysis]);

  useEffect(() => {
    handleSetParsedData();
  }, [data]);

  if (isGenerate) {
    return (
      <GenerateLoading
        progress={progressPortion({
          progress: Number((progress / 2).toFixed(0)),
          isQuerying: isTargetAudience.isQuerying,
          isContentAvailable: parsedData?.brand_perception.length !== 0,
        })}
        project={project}
        section={section}
      />
    );
  }

  return (
    <Fragment>
      {/* content */}
      <div className="flex flex-col gap-24 text-black-redx">
        {parsedData && (
          <Fragment>
            <div className="flex flex-col gap-5">
              <div className="flex items-center justify-between">
                <h3 className="font-bold text-16 leading-19">
                  Brand Perception
                </h3>
                {isYourRolesCanEdit(roles) && !isEditing && (
                  <RegenerateButton
                    onSubmit={(formData) =>
                      handleRegenerateButton(formData.prompt)
                    }
                    limit={total_regenerate}
                    maxLimit={maxGenerateLimit}
                  />
                )}
              </div>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.brand_perception)}
                  onChange={(html) => {
                    const json = markdownToJson(html);

                    setValue("brand_perception", jsonToMarkdown(json));
                    setBrandPerception(json);
                  }}
                />
              ) : (
                <>
                  { isLoading ? (
                      <div
                        className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ) : (
                      <TargetAudienceList items={brandPerception} />
                    )
                  }
                </>
              )}
            </div>

            <div className="flex flex-col gap-5">
              <h3 className="font-bold text-16 leading-19">
                Sector Perception
              </h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(parsedData.sector_perception)}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue("sector_perception", jsonToMarkdown(json));
                    setSectorPerception(json);
                  }}
                />
              ) : (
                <>
                  { isLoading ? (
                      <div
                        className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ) : (
                      <TargetAudienceList items={sectorPerception} />
                    )
                  }
                </>
              )}
            </div>

            <div className="flex flex-col gap-5">
              <h3 className="font-bold text-16 leading-19">
                Product Category Perception
              </h3>
              {isEditing ? (
                <MarkdownEditor
                  content={jsonToMarkdown(
                    parsedData.product_category_perception,
                  )}
                  onChange={(html) => {
                    const json = markdownToJson(html);
                    setValue(
                      "product_category_perception",
                      jsonToMarkdown(json),
                    );
                    setProductCategoryPerception(json);
                  }}
                />
              ) : (
                <>
                  { isLoading ? (
                      <div
                        className='w-full h-100 px-15 mt-5 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
                      />
                    ) : (
                      <TargetAudienceList items={productCategoryPerception} />
                    )
                  }
                </>
              )}
            </div>
          </Fragment>
        )}
      </div>

      <div className="flex w-full justify-end items-center my-24 gap-x-15">
        {isYourRolesCanEdit(roles) &&
          (isEditing ? (
            <Fragment>
              <button
                className="inline-flex items-center gap-x-4 text-error-redx cursor-pointer"
                onClick={() => {
                  handleSetParsedData();
                  toggleIsEditing();
                }}
              >
                <span className="text-15 font-semibold">Cancel</span>
                <Icon icon="lucide:x" className="w-20 h-20" />
              </button>
              <button
                className="inline-flex items-center gap-x-4 text-blue-redx cursor-pointer"
                onClick={handleSaveData}
              >
                <span className="text-15 font-semibold">Save</span>
                <Icon icon="lucide:save" className="w-20 h-20" />
              </button>
            </Fragment>
          ) : (
            <EditButton toggleEditing={toggleIsEditing} />
          ))}
      </div>
      <div className='flex w-full pb-60 mr-1'>
        {!isLoading && sources && (
          <SourcesList sources={sources as SourcesType[]} />
        )}
        {isLoading && (
          <div className='inline-flex gap-10'>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                className='min-w-363 h-65 flex items-center px-15 py-12 gap-x-12 border-1 border-soft-purple-redx rounded-10 full animate-pulse bg-soft-purple-redx'
              />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default CardPerceptionAnalysis;
