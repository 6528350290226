import React from "react";
import MarkdownIt from "markdown-it";
import mila from "markdown-it-link-attributes";
import { adjustOrderedList } from "@/Utils/markdownAdjustOrderList";

// Konfigurasi MarkdownIt
const md = new MarkdownIt({
  html: true,
  xhtmlOut: false,
  breaks: true,
  linkify: true,
  typographer: true,
  langPrefix: "", // Disable language prefix in code block
})
  .disable("code")
  .use(mila, {
    attrs: {
      target: "_blank",
      rel: "noopener noreferrer",
    },
  });

const MarkdownView: React.FC<{ content: string | null }> = ({ content }) => {
  if (!content) return null;

  let htmlContent = md.render(content);

  htmlContent = adjustOrderedList(htmlContent);

  return (
    <div
      className='markdown-content'
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default MarkdownView;
