import React from "react";
import TextArea from "../../TextAreaInput";
import MarkdownView from "@/Components/MardownView";

const CardInput: React.FC<{
  isFetching?: boolean;
  isEditing: boolean;
  showTitle?: boolean;
  title: string;
  value: any;
  defaultValue: any;
  error: string | undefined;
  onChange: (e: any) => void;
  placeholder: string;
}> = ({
  isFetching = false,
  isEditing,
  title,
  value,
  defaultValue,
  error,
  onChange,
  placeholder,
  showTitle = true,
}) => {
  return (
    <div className='flex flex-col gap-4 '>
      {showTitle && (
        <h3 className='font-semibold text-12 leading-14'>{title}</h3>
      )}
      {isEditing ? (
        <TextArea
          name={title.toLowerCase()}
          value={value}
          error={error}
          containerClassName='border-b-2 border-soft-purplestroke-redx text-black-redx'
          className='mt-1 block w-full !text-14 resize-none overflow-custom'
          rows={2}
          placeholder={placeholder}
          isFocused={false}
          onChange={onChange}
        />
      ) : (
        !isFetching && (
          <div className='text-14 py-4 min-h-50'>
            <MarkdownView content={defaultValue} />
          </div>
        )
      )}
      {isFetching && (
        <div className='text-14 py-4 min-h-50'>
          <div className='w-full h-20 animate-pulse bg-soft-purple-redx rounded-full  mb-14' />
        </div>
      )}
    </div>
  );
};

export default CardInput;
