import React from "react";

import ModalCenter from "@/Components/Modals/Center";

const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  handleGenerate: () => void;
}> = ({ isOpen, onClose, handleGenerate }) => {
  return (
    <ModalCenter hasButtonClose={false} isOpen={isOpen} onClose={onClose}>
      <div className="relative w-full md:max-w-sm">
        <div className="flex transition-transform transform duration-500">
          <div className="w-full flex-shrink-0 p-49">
            <div className="mb-40 max-w-xl">
              <h1 className="font-bold md:text-24 text-35 text-black-redx mb-16 text-center">
                Did you know you can generate multiple archetypes?
              </h1>
              <p className="text-grey-redx text-15 text-center">
                Are you sure you generated only one archetype? You can choose
                multiple archetypes at once or add new ones.
              </p>
            </div>
            <div className="flex md:flex-col flex-row w-full gap-24">
              <button
                className="w-full py-15 bg-transparent border-blue-redx border-2 text-blue-redx font-semibold rounded-10"
                onClick={onClose}
              >
                Back to Audience Archetype
              </button>
              <button
                className="w-full py-15 bg-blue-redx border-blue-redx border-2 text-white font-semibold rounded-10"
                onClick={handleGenerate}
              >
                Generate Just One Archetype
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default ConfirmationModal;
