import useValidateProject from "@/Hooks/react-query/useValidateProject";
import useUser from "@/Hooks/react-query/useUser";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Cookies from "js-cookie";

const Redirect = () => {
  const navigate = useNavigate();
  const { project_slug } = useParams<{ project_slug: string }>();
  const { data: project, isLoading } = useValidateProject(project_slug ?? "");
  const { data: user, isLoading: userLoading } = useUser();

  useEffect(() => {
    if (!isLoading && !userLoading) {
      if (_.isEmpty(project)) {
        navigate(`/404`);
      } else if (
        project?.owner.id != user?.id &&
        !project?.users.find(({ id }) => id === user?.id)
      ) {
        Cookies.set("project_found", "false", { expires: 1 / 2880 });
        Cookies.set("project_owner_name", project?.owner.name || "", {
          expires: 1 / 2880,
        });
        Cookies.set("project_owner_email", project?.owner.email || "", {
          expires: 1 / 2880,
        });
        navigate(`/projects`);
      } else {
        window.location.href = `/${project_slug}/submission`;
      }
    }
  }, [project, user, isLoading, userLoading]);
  return <></>;
};

export default Redirect;
