import { ProjectProps } from "@/Types/projects";
import { fetcher } from "@/Services/axios";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { GetDifferentResponse } from "@/Types/audience-archetype";

const getDifferent = async (
  project: ProjectProps | string,
): Promise<GetDifferentResponse> => {
  const project_slug = typeof project === "string" ? project : project.slug;
  const url = `/${project_slug}/audience-archetype/get-different`;
  try {
    const response = await fetcher.get(url);

    return response.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || "An error occurred");
  }
};

const useDifferentArchetypes = (
  project: ProjectProps | string,
): UseQueryResult<GetDifferentResponse, Error> => {
  const project_slug = typeof project === "string" ? project : project.slug;
  return useQuery<GetDifferentResponse, Error>({
    queryKey: ["different_archetypes", project_slug], // use project.slug to ensure cache uniqueness per project
    queryFn: () => getDifferent(project),
    refetchInterval: 5000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
};

export default useDifferentArchetypes;
