import { useEffect, InputHTMLAttributes, useState, Fragment } from "react";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
  Radio,
  RadioGroup,
  Field,
  Label,
  Checkbox,
} from "@headlessui/react";
import { Icon } from "@iconify/react/dist/iconify.js";

interface iTag {
  isFocused?: boolean;
  containerClassName?: string;
  error?: string;
  initialRoles?: string[];
  selectedRoles?: string[];
  onChangeRoles?: (roles: string[]) => void;
  isClearRoles?: boolean;
  onClearRolesComplete?: () => void;
  canDelegateOwnership?: boolean;
  onChooseOwnership?: (status: boolean) => void;
}

export default function PopoverRolesInput({
  className = "",
  containerClassName = "",
  isFocused = false,
  placeholder = "",
  error = "",
  initialRoles = [],
  selectedRoles = [],
  onChangeRoles = ([]) => {},
  isClearRoles = false,
  onClearRolesComplete = () => {},
  canDelegateOwnership = false,
  onChooseOwnership = (_status: boolean) => {},
  ...props
}: InputHTMLAttributes<HTMLInputElement> & iTag) {
  const permissions = ["Edit", "View Only"];

  const [selectedPersmission, setSelectedPermission] = useState("");
  const [checkedRoles, setCheckedRoles] = useState<string[]>(selectedRoles);
  const [roles, _setRoles] = useState<string[]>(initialRoles);
  const [selectedPersmissionOwner, setSelectedPermissionOwner] = useState("");

  useEffect(() => {
    if (!selectedRoles.includes("View Only")) {
      setSelectedPermission("Edit");
    } else {
      setSelectedPermission("View Only");
    }
  }, [selectedRoles.length > 0]);

  useEffect(() => {
    onChangeRoles(checkedRoles);
  }, [checkedRoles]);

  useEffect(() => {
    if (isClearRoles) {
      setCheckedRoles([]);
      setSelectedPermission("Edit");
      onClearRolesComplete();
    }
  }, [isClearRoles, onClearRolesComplete]);

  const handleCheckboxChange = (role: string) => {
    setCheckedRoles((prevCheckedRoles) =>
      prevCheckedRoles.includes(role)
        ? prevCheckedRoles.filter((r) => r !== role)
        : [...prevCheckedRoles, role],
    );
  };

  return (
    <Popover className="bg-transparent relative">
      <PopoverButton
        className={
          "flex items-center justify-between gap-20 font-normal text-black-redx py-12 ps-8 focus:outline-none data-[active]:text-black-redx data-[hover]:text-black-redx data-[focus]:outline-1 data-[focus]:outline-black-redx " +
          containerClassName +
          (error ? " border-red-500" : "")
        }
      >
        {checkedRoles.length > 0 ? (
          <div className={"text-14 font-normal leading-20 " + className}>
            {checkedRoles.join(", ")}
          </div>
        ) : (
          <div className="text-14 font-normal leading-20 text-[#E2E2E2]">
            Choose your team role here
          </div>
        )}
        <Icon icon="mdi:chevron-down" className="w-20 h-20" />
      </PopoverButton>
      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel
          anchor="bottom end"
          className="shadow-[0px_0px_12px_0px_rgba(0,0,0,0.15)] mt-5 rounded-8 bg-white [--anchor-gap:var(--spacing-5)] flex w-[var(--button-width)] min-w-200 flex-col"
        >
          {({ close }) => (
            <div className="relative p-10 rounded-10 w-[90%] h-[90%] mx-auto mt-5">
              <RadioGroup
                className="flex flex-col gap-5"
                value={selectedPersmission}
                onChange={(value) => {
                  setSelectedPermission(value);
                  if (value == "View Only") {
                    setCheckedRoles(["View Only"]);
                  } else {
                    setCheckedRoles([]);
                  }
                }}
                aria-label="Server size"
              >
                {permissions.map((permission, index) => (
                  <Fragment key={index}>
                    <Field className="flex items-center gap-5">
                      <Radio
                        value={permission}
                        disabled={props.disabled}
                        className="group flex size-20 items-center justify-center rounded-full border bg-white border-2 data-[checked]:border-2 data-[checked]:border-blue-purple-redx data-[disabled]:bg-gray-200 data-[disabled]:border-gray-200"
                      >
                        <span className="invisible size-10 rounded-full bg-blue-purple-redx group-data-[checked]:visible group-data-[disabled]:bg-gray-400" />
                      </Radio>
                      <Label className="font-normal text-14 leading-20 ms-5">
                        {permission}
                      </Label>
                    </Field>
                    {permission == "Edit" &&
                      roles.map((role, i) => (
                        <Field
                          key={i}
                          className="flex items-center gap-5 ms-30"
                        >
                          <Checkbox
                            disabled={
                              selectedPersmission !== "Edit" || props.disabled
                            }
                            checked={checkedRoles.includes(role)}
                            className="group block size-20 rounded border bg-white border-2 data-[checked]:bg-blue-purple-redx data-[disabled]:bg-gray-200"
                            onChange={(_checked) => {
                              handleCheckboxChange(role);
                            }}
                          >
                            <svg
                              className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M3 8L6 11L11 3.5"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Checkbox>
                          <Label className="font-normal text-14 leading-20 ms-5">
                            {role}
                          </Label>
                        </Field>
                      ))}
                  </Fragment>
                ))}
              </RadioGroup>

              {canDelegateOwnership && (
                <RadioGroup
                  className="flex flex-col gap-5 mt-7"
                  value={selectedPersmissionOwner}
                  onChange={() => {
                    setSelectedPermissionOwner("");
                    close();
                    onChooseOwnership(true);
                  }}
                  aria-label="Server size"
                >
                  <Field className="flex items-center gap-5">
                    <Radio
                      value="Owner"
                      disabled={props.disabled}
                      className="group flex size-20 items-center justify-center rounded-full border bg-white border-2 data-[checked]:border-2 data-[checked]:border-blue-purple-redx data-[disabled]:bg-gray-200 data-[disabled]:border-gray-200"
                    >
                      <span className="invisible size-10 rounded-full bg-blue-purple-redx group-data-[checked]:visible group-data-[disabled]:bg-gray-400" />
                    </Radio>
                    <Label className="font-normal text-14 leading-20 ms-5">
                      Owner
                    </Label>
                  </Field>
                </RadioGroup>
              )}
            </div>
          )}
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
