import { Link, LinkProps } from "react-router-dom";

interface ResponsiveNavLinkProps extends LinkProps {
  active?: boolean;
  className?: string;
}

export default function ResponsiveNavLink({
  active = false,
  className = "",
  children,
  ...props
}: ResponsiveNavLinkProps) {
  return (
    <Link
      {...props}
      className={`w-full flex items-start ps-3 pe-4 py-2 border-l-4 ${
        active
          ? "border-indigo-400 text-indigo-700 bg-indigo-50 focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700"
          : "border-transparent hover:font-bold"
      } font-medium focus:outline-none transition duration-150 ease-in-out ${className}`}
    >
      {children}
    </Link>
  );
}
