import { setTargetAudienceAudienceInsights } from "@/Context/actions/target_audience";
import { ProjectAction } from "@/Context/actions/projectActions";
import { SubsectionDataType } from "@/Types/projects";
import axios from "axios";
import { Dispatch } from "react";

export const handleEditedTargetAudienceAudienceInsights =
  (dispatch: Dispatch<ProjectAction>) =>
  async (project_slug: string, history_id: number, content: string) => {
    try {
      const { data } = await axios.put<{ data: SubsectionDataType }>(
        route("project.history.update", { project_slug, history: history_id }),
        {
          content: content,
        },
      );

      dispatch(setTargetAudienceAudienceInsights(data.data));
    } catch (err) {
      // TODO: dispatch error state
    }
  };

export const handleSetTargetAudienceAudienceInsights =
  (dispatch: Dispatch<ProjectAction>) => async (data: SubsectionDataType) => {
    dispatch(setTargetAudienceAudienceInsights(data));
  };
