import toast from "react-hot-toast";
import { Icon } from "@iconify/react";

const UserHasApproved: React.FC<{ t: any }> = ({ t }) => {
  return (
    <div
      className="inline-flex items-center py-10 px-20 mb-4 text-10 text-white bg-success-redx rounded-lg"
      style={{
        opacity: t.visible ? 1 : 0,
        transition: "opacity 100ms ease-in-out",
      }}
    >
      <p className="mr-10">
        Your user account is already approved. Please fill the email and password to login.
      </p>
      <Icon
        icon="mdi:close"
        className="w-15 h-15 cursor-pointer"
        onClick={() => toast.remove()}
      >
        X
      </Icon>
    </div>
  );
};

export default UserHasApproved;
