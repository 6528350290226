import AvatarLabel from "@/Components/AvatarLabel";
import ButtonCopyLink from "@/Components/ButtonCopyLink";
import InputError from "@/Components/InputError";
import InputLabel from "@/Components/InputLabel";
import ModalCenter from "@/Components/Modals/Center";
import PopoverRolesInput from "@/Components/PopoverRolesInput";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInputTags from "@/Components/TextInputTags";
import { ExternalUser, User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useCallback, useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import RequestAccessModal from "./RequestAccessModal";
import TransferOwnershipModal from "./TransferOwnershipModal";
import UnsavedChangesPopup from "./UnsavedChangesPopup";
import { fetcher } from "@/Services/axios";

interface UserPayload {
  id: number;
  email: string;
  roles: string[];
}

interface MainForm {
  route: string | undefined;
  routeParams: Record<string, string>;
  members: UserPayload[];
  external_members: ExternalUser[];
}

const UpdateFormModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  project: ProjectProps;
  initialUsers: User[];
  appUrl: string;
}> = ({ isOpen, onClose, project, initialUsers, appUrl }) => {
  const [isClearTags, setIsClearTags] = useState<boolean>(false);
  const [isClearRoles, setIsClearRoles] = useState<boolean>(false);
  const [validatedUsers, setValidatedUsers] = useState<User[]>(project.users);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [isOpenModalRequestAccess, setIsOpenModalRequestAccess] =
    useState<boolean>(false);
  const [requestAccessRoles, setRequestAccessRoles] = useState<string[]>([]);
  const [validatedExternalUsers, setValidatedExternalUsers] = useState<
    ExternalUser[]
  >(project.requested_users);
  const closeModalRequestAccess = () => setIsOpenModalRequestAccess(false);
  const [openPopupOwnership, setOpenPopupOwnership] = useState(false);
  const [excludedUsers, setExcludedUsers] = useState<User[]>([
    project.owner,
    ...validatedUsers,
    ...validatedExternalUsers.map((user: ExternalUser): User => {
      return {
        id: 0,
        email: user.email,
        avatar: "",
        avatar_url: "",
        email_verified_at: "",
        created_at: "",
        updated_at: "",
        name: "",
        roles: user.roles,
        whatsapp_number: "",
        status: "",
      };
    })
  ]);
  const [popupUnsave, setPopupUnsave] = useState<boolean>(false);

  const navigate = useNavigate();

  // form update project
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<MainForm>({
    defaultValues: {
      route: undefined,
      routeParams: {},
      members: project.users.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: [],
    },
  });

  // form check user
  const {
    setValue: setCheckValue,
    getValues: getCheckValues,
    reset: resetCheck,
  } = useForm<{ email: string[]; roles: string[] }>({
    defaultValues: {
      email: [],
      roles: [],
    },
  });

  // form transfer ownership
  const { setValue: setTransferValue, getValues: getTransferValues } = useForm<{
    project_id: number;
    new_owner_id: number;
  }>({
    defaultValues: {
      project_id: project.id,
      new_owner_id: 0,
    },
  });

  const handleClearTags = () => {
    setIsClearTags(true);
    // clear formCheckEmailsUser data
    resetCheck({ email: [], roles: [] });
  };

  const handleClearTagsComplete = () => {
    setIsClearTags(false);
  };

  const handleClearRoles = () => {
    setIsClearRoles(true);
  };

  const handleClearRolesComplete = () => {
    setIsClearRoles(false);
  };

  // handle add user
  const handleAddUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      const response = await fetcher.post(`/projects/validate-teammates`, {
        emails: getCheckValues("email"),
      });
      const invalidEmailsData = response.data.invalidEmails;
      const validatedEmails = response.data.validatedEmails;
      // handle invalid emails
      if (invalidEmailsData.length > 0) {
        setInvalidEmails(invalidEmailsData);
        setIsOpenModalRequestAccess(true);
      }

      // handle validated emails
      if (validatedEmails.length > 0) {
        // append user to validated users list their roles where email is from formCheckEmailsUser.data.email list
        const users = validatedEmails.map((email: string, _index: number) => {
          const user = initialUsers.find((user) => user.email === email);
          return { ...user, roles: getCheckValues("roles") };
        }) as User[];
        setValidatedUsers([...validatedUsers, ...users]);
        setExcludedUsers([...excludedUsers, ...users]);
        // set mainForm members
        setValue("members", [
          ...watch("members"),
          ...users.map((user) => ({
            id: user.id,
            email: user.email,
            roles: user.roles,
          })),
        ]);
        handleClearTags();
        handleClearRoles();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // remove validated user using useCallback
  const removeUser = useCallback(
    (user: User) => {
      setValidatedUsers(validatedUsers.filter((u) => u.email !== user.email));
      setValue(
        "members",
        watch("members").filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedUsers, excludedUsers, setValue, watch],
  );

  const onSubmit: SubmitHandler<MainForm> = async () => {
    setPopupUnsave(false);
    const data = {
      ...getCheckValues(),
      members: validatedUsers.map((user) => ({
        id: user.id,
        email: user.email,
        roles: user.roles,
      })),
      external_members: validatedExternalUsers,
    };
    try { 
      await fetcher.put(`/projects/${project.id}`, data);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleStopPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const handleCloseModal = () => {
    onClose();
    setValidatedUsers(project.users);
    closeModalRequestAccess();
    setPopupUnsave(false);
  };

  const handleCloseModalRequestAccess = () => {
    if (getCheckValues("email").length > 0) {
      handleClearTags();
    }
    if (getCheckValues("roles").length > 0) {
      handleClearRoles();
    }
    setInvalidEmails([]);
    setRequestAccessRoles([]);
    closeModalRequestAccess();
  };

  const handleRequestAccess = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (getCheckValues("email").length > 0) {
      handleClearTags();
    }
    if (getCheckValues("roles").length > 0) {
      handleClearRoles();
    }
    closeModalRequestAccess();

    // append to validatedExternalUsers with roles
    const externalUsers = invalidEmails.map((email) => {
      return { email, roles: requestAccessRoles };
    });
    setValidatedExternalUsers([...validatedExternalUsers, ...externalUsers]);

    setValue("external_members", [
      ...watch("external_members"),
      ...externalUsers,
    ]);

    setExcludedUsers([
      ...excludedUsers,
      ...externalUsers.map((user: ExternalUser): User => {
        return {
          id: 0,
          email: user.email,
          avatar: "",
          avatar_url: "",
          email_verified_at: "",
          created_at: "",
          updated_at: "",
          name: "",
          roles: user.roles,
          whatsapp_number: "",
          status: "",
        };
      }),
    ]);
  };

  const removeExternalUser = useCallback(
    (user: ExternalUser) => {
      setValidatedExternalUsers(
        validatedExternalUsers.filter((u) => u.email !== user.email),
      );
      setValue(
        "external_members",
        watch("external_members").filter((u) => u.email !== user.email),
      );
      setExcludedUsers(excludedUsers.filter((u) => u.email !== user.email));
    },
    [validatedExternalUsers, excludedUsers, setValue, watch],
  );

  const handleTransferOwnership = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    try {
      await fetcher.post(`/projects/transfer-ownership`, getTransferValues());
      setOpenPopupOwnership(false);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  // can delegate ownership show when validatedUsers email is in project.users email
  const canDelegateOwnership = (user: User) => {
    return project.users.some((u) => u.email === user.email);
  };

  useEffect(() => {
    if (!isOpen) {
      resetCheck({ email: [], roles: [] });
      setExcludedUsers([project.owner, ...validatedUsers, ...validatedExternalUsers.map((user: ExternalUser): User => {
        return {
          id: 0,
          email: user.email,
          avatar: "",
          avatar_url: "",
          email_verified_at: "",
          created_at: "",
          updated_at: "",
          name: "",
          roles: user.roles,
          whatsapp_number: "",
          status: "",
        };
      })]);
      setValue(
        "members",
        project.users.map((user) => ({
          id: user.id,
          email: user.email,
          roles: user.roles,
        })),
      );
    }
  }, [isOpen, resetCheck, setValue, project.owner, project.users]);

  return (
    <ModalCenter isOpen={isOpen} onClose={() => setPopupUnsave(true)}>
      <div
        onClick={handleStopPropagation}
        className="relative w-[56rem] md:max-w-xs"
      >
        <div className="w-full">
          <div className="rounded-10 relative p-[36px] md:py-50 md:px-30 max-h-[80vh] overflow-y-auto">
            {popupUnsave && (
              <UnsavedChangesPopup
                onBack={() => setPopupUnsave(false)}
                onClose={handleCloseModal}
              />
            )}
            <div className="flex gap-20 items-center justify-items-center">
              <h1 className="font-medium text-25 text-black leading-30">
                Add your teammates!
              </h1>
            </div>
            <div className="flex flex-col gap-[36px]">
              <div className="flex items-start gap-24 mt-24">
                <div className="flex-1">
                  <div className="flex items-start md:flex-col justify-between gap-24">
                    <div className="flex-1 md:w-full">
                      <InputLabel
                        htmlFor="emails"
                        value="Email"
                        className="!text-black-redx mb-4"
                      />
                      <TextInputTags
                        id="emails"
                        placeholder="Input your teammates email here"
                        containerClassName="text-black-redx border-b-2 border-soft-purplestroke-redx"
                        className="px-0 !flex-grow"
                        onChangeTags={(tags) => {
                          setCheckValue("email", tags);
                        }}
                        initialTags={[]}
                        initialUsers={initialUsers}
                        exludedUsers={excludedUsers}
                        isClearTags={isClearTags}
                        onClearTagsComplete={handleClearTagsComplete}
                      />
                    </div>
                    <div className="w-7/24 md:w-full">
                      <InputLabel
                        className="!text-black-redx"
                        htmlFor="roles"
                        value="Roles"
                      />
                      <PopoverRolesInput
                        containerClassName="w-full !ps-0 text-black-redx border-b-2 border-soft-purplestroke-redx"
                        initialRoles={["Strategist", "Creative", "Content"]}
                        onChangeRoles={(roles) => {
                          setCheckValue("roles", roles);
                          if (roles.length > 0) {
                            setRequestAccessRoles(roles);
                          }
                        }}
                        isClearRoles={isClearRoles}
                        onClearRolesComplete={handleClearRolesComplete}
                      />
                    </div>
                  </div>
                </div>
                <PrimaryButton
                  onClick={(e) => handleAddUser(e)}
                  className="!text-16 !leading-18 !font-semibold !px-22 !py-14 w-100 shrink-0 bg-transparent border-1 border-blue-redx !text-blue-redx"
                  disabled={
                    getCheckValues("email").length === 0 ||
                    getCheckValues("roles").length === 0
                  }
                >
                  Add
                </PrimaryButton>
              </div>
              <div className="flex flex-col gap-12">
                <InputLabel
                  className="!text-black-redx"
                  htmlFor="members"
                  value="Members"
                />
                <div className="overflow-y-auto max-h-5/6">
                  {project.owner && (
                    <div className="flex items-center gap-24 py-5">
                      <div className="flex-1">
                        <div className="flex items-center justify-between gap-20">
                          <div className="flex-1">
                            <AvatarLabel
                              src={project.owner.avatar_url}
                              title={project.owner.name}
                              subtitle={project.owner.email}
                            />
                          </div>
                          <div className="w-8/24">
                            <p className="text-black-redx/50 ps-8 font-semibold text-14 leading-20">
                              Owner
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="flex items-center justify-center">
                          <Icon
                            icon="uil:trash"
                            className="w-24 h-24 text-black-redx/50"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {validatedUsers.map((user, i) => (
                    <div
                      key={user.email}
                      className="flex items-center gap-24 py-5"
                    >
                      <div className="flex-1">
                        <div className="flex items-center justify-between gap-20">
                          <div className="flex-1">
                            <AvatarLabel
                              src={user.avatar_url}
                              title={user.name}
                              subtitle={user.email}
                            />
                          </div>
                          <div className="relative w-8/24">
                            <PopoverRolesInput
                              containerClassName="w-full"
                              className="font-semibold"
                              initialRoles={[
                                "Strategist",
                                "Creative",
                                "Content",
                              ]}
                              selectedRoles={user.roles}
                              onChangeRoles={(roles) => {
                                // update mainForm members roles
                                setValue(
                                  "members",
                                  watch("members").map((m) => {
                                    if (m.email === user.email) {
                                      return { ...m, roles: roles };
                                    }
                                    return m;
                                  }),
                                );

                                // update validatedUsers roles
                                setValidatedUsers(
                                  validatedUsers.map((u) => {
                                    if (u.email === user.email) {
                                      return { ...u, roles: roles };
                                    }
                                    return u;
                                  }),
                                );
                              }}
                              error={
                                errors.members &&
                                errors.members[i] &&
                                (errors.members[i].roles as any)
                              }
                              canDelegateOwnership={canDelegateOwnership(user)}
                              onChooseOwnership={(status) => {
                                setOpenPopupOwnership(status);
                                setTransferValue("new_owner_id", user.id);
                              }}
                            />
                            <InputError
                              message={
                                errors.members &&
                                errors.members[i] &&
                                (errors.members[i].roles as any)?.message
                              }
                              light={false}
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <div className="flex items-center justify-center">
                          <Icon
                            onClick={() => removeUser(user)}
                            icon="uil:trash"
                            className="w-24 h-24 text-black-redx cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  {validatedExternalUsers &&
                    validatedExternalUsers.length > 0 && (
                      <div className="py-10">
                        <InputLabel
                          className="!text-black-redx"
                          htmlFor="nonmembers"
                          value="Requested Members"
                        />
                      </div>
                    )}
                  {validatedExternalUsers &&
                    validatedExternalUsers.map((user, _i) => {
                      return (
                        <div
                          key={user.email}
                          className="flex items-center justify-between gap-20 py-8"
                        >
                          <div className="flex-1">
                            <div className="flex items-center gap-10">
                              <Icon
                                icon="mdi:user-circle"
                                className="w-32 h-32"
                              />
                              <div className="font-medium text-black">
                                <div className="text-14 font-semibold leading-16 text-black-redx mb-5">
                                  {user.email}
                                </div>
                                <div className="text-12 text-grey-redx leading-14">
                                  {user.email}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-6/24">
                            <p className="text-14">{user.roles.join(", ")}</p>
                          </div>
                          <div className="w-100">
                            <div className="flex items-center justify-center">
                              <Icon
                                onClick={() => removeExternalUser(user)}
                                icon="uil:trash"
                                className="w-24 h-24 text-black-redx cursor-pointer"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='flex items-end justify-between py-10 border-b-2 border-soft-purple-redx"'>
                <div className="text-black-redx">
                  <p className="text-12 leading-16 font-semibold">
                    Share Project Link
                  </p>
                  <p className="text-14 font-normal leading-18">
                    {appUrl}/{project.slug}
                  </p>
                </div>
                <ButtonCopyLink value={`${appUrl}/${project.slug}`} />
              </div>
              <div className="flex justify-end items-center mt-30 gap-x-10">
                <PrimaryButton
                  className="!text-16  !leading-18 ms-4 px-31 py-16 bg-blue-redx hover:bg-blue-redx active:bg-blue-redx"
                  disabled={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </PrimaryButton>
              </div>
            </div>
          </div>

          <TransferOwnershipModal
            isOpen={openPopupOwnership}
            onClose={() => setOpenPopupOwnership(false)}
            onClickRequest={handleTransferOwnership}
          />

          <RequestAccessModal
            roles={requestAccessRoles}
            emails={invalidEmails}
            isOpen={isOpenModalRequestAccess}
            onClose={handleCloseModalRequestAccess}
            onClickRequest={(e) => handleRequestAccess(e)}
          />
        </div>
      </div>
    </ModalCenter>
  );
};

export default UpdateFormModal;
