import useCopyLink from "@/Hooks/useCopyLink";
import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const ButtonCopyLink: React.FC<{ value: string }> = ({ value }) => {
  const { isCopied, copyLink } = useCopyLink();

  const handleClick = () => {
    copyLink(value);
  };

  if (isCopied) {
    return (
      <div className="flex items-center justify-center gap-5 text-[#066C0A]">
        <Icon
          icon="mdi:check-circle-outline"
          className="w-24 h-24 text-[#066C0A]"
        />
        <span className="text-14 font-semibold leading-18">Link Copied</span>
      </div>
    );
  }

  return (
    <button
      onClick={handleClick}
      className="flex items-center justify-center gap-5 text-blue-redx"
    >
      <Icon icon="ph:link" className="w-24 h-24 text-blue-redx" />
      <span className="text-14 font-semibold leading-18">Copy Link</span>
    </button>
  );
};

export default ButtonCopyLink;
