import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      navigate("/projects");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return <></>;
};
