import PrimaryButton from "@/Components/PrimaryButton";
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout";
import { PageProps, User } from "@/Types";
import { ProjectProps } from "@/Types/projects";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import CreateFormModal from "./Partials/CreateFormModal";
import NoAccessModal from "./Partials/NoAccessModal";
import ProjectCard from "./Partials/ProjectCard";
import useUser from "@/Hooks/react-query/useUser";
import useProjects from "@/Hooks/react-query/useProjects";
import useApprovedUsers from "@/Hooks/react-query/useApprovedUsers";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function List({
  projects,
}: PageProps<{ projects: ProjectProps[] }>) {
  const { data: auth } = useUser();
  const { data: approvedUsers } = useApprovedUsers();
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const [allProjects, setAllProjects] = useState<ProjectProps[]>(projects);
  const [selectedSortBy, setSelectedSortBy] = useState(
    "Creation Date (Newest - Oldest)",
  );
  const [isOpenNoAccess, setIsOpenNoAccess] = useState(false);
  const { data, isLoading } = useProjects();
  const closeModalNoAccess = () => setIsOpenNoAccess(false);

  const sortBys = [
    { name: "Creation Date (Newest - Oldest)" },
    { name: "Creation Date (Oldest - Newest)" },
    { name: "Project Name (A - Z)" },
    { name: "Project Name (Z - A)" },
  ];

  const appUrl = new URL(window.location.href);
  const baseUrl = appUrl.origin;

  const [projectFound, setProjectFound] = useState(true);
  const [projectMessage, setProjectMessage] = useState<string>();

  useEffect(() => {
    setInitialUsers(approvedUsers?.users || []);
    setProjectFound(Cookies.get("project_found") == "false" ? false : true);
  }, [approvedUsers]);

  useEffect(() => {
    if (!isLoading && data?.projects?.length) {
      setAllProjects(data.projects);
    }
  }, [data]);

  useEffect(() => {
    if (projectFound === false) {
      const name = Cookies.get("project_owner_name");
      const email = Cookies.get("project_owner_email");

      setProjectMessage(`You need an invitation to access this Ainstein project. 
Please contact the project owner, <b>${name} (${email})</b>, to be added as a teammate.`);
      setIsOpenNoAccess(true);
    }
  }, [projectFound]);

  const filterProjects = (projects: ProjectProps[], search: string) => {
    if (search === "") {
      return projects;
    }
    return projects?.filter((project) => {
      return (
        project.name?.toLowerCase().includes(search.toLowerCase()) ||
        project.owner.name?.toLowerCase().includes(search.toLowerCase()) ||
        project.owner.email?.toLowerCase().includes(search.toLowerCase()) ||
        project.users?.some((u) =>
          u.name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.users?.some((u) =>
          u.email?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.brand_name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.product_name?.toLowerCase().includes(search.toLowerCase()),
        ) ||
        project.submissions?.some((s) =>
          s.category?.name.toLowerCase().includes(search.toLowerCase()),
        )
      );
    });
  };

  // handler sort by project from allProjects state and from filterProjects function
  const handlerSortBy = (sortBy: string) => {
    setSelectedSortBy(sortBy);
    let sortedProjects = allProjects;
    switch (sortBy) {
      case "Creation Date (Newest - Oldest)":
        sortedProjects = allProjects.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        break;
      case "Creation Date (Oldest - Newest)":
        sortedProjects = allProjects.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        );
        break;
      case "Project Name (A - Z)":
        sortedProjects = allProjects.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        break;
      case "Project Name (Z - A)":
        sortedProjects = allProjects.sort((a, b) =>
          b.name.localeCompare(a.name),
        );
        break;
      default:
        break;
    }
    setAllProjects([...sortedProjects]);
  };

  return (
    <AuthenticatedLayout>
      <title>Project List</title>

      <div className="pb-40 pt-73">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {/* Title */}
          <div className="flex flex-row items-center justify-center mt-20">
            <div className="basis-1/2">
              <div className="flex flex-col gap-10">
                <h1 className="text-black-redx text-32 leading-[36px] font-semibold sm:text-[24px] sm:leading-[28px]">
                  Your AInstein Projects
                </h1>
                <p className="text-grey-redx text-16 leading-20 font-normal">
                  Create, search, and choose your marketing project here.
                </p>
              </div>
            </div>
            <div className="basis-1/2 text-right">
              <PrimaryButton
                onClick={() => setIsOpen(true)}
                className="ms-4 w-[187px] bg-blue-purple-redx"
                bgColor="blue-700"
                disabled={false}
              >
                Create Project
              </PrimaryButton>
            </div>
          </div>
          {/* Filter */}
          <div className="flex items-center gap-20 py-20 my-20">
            <div className="grow">
              <div className="bg-white border-1 border-stroke-redx rounded-8 flex flex-row items-center px-16 py-2">
                <Icon
                  icon="akar-icons:search"
                  className="text-black-redx text-20 leading-24 font-normal mx-2"
                />
                <input
                  type="text"
                  placeholder="Search project name / product name / brand name / owner name here"
                  className="w-full text-grey-redx text-16 leading-20 font-normal border-none focus:outline-none focus:ring-0"
                  onChange={(e) => {
                    const search = e.target.value;
                    const filteredProjects = filterProjects(
                      data.projects,
                      search,
                    );
                    setAllProjects(filteredProjects);
                  }}
                />
              </div>
            </div>
            <div className="">
              <div className="text-right">
                {/* dropdown sort by */}
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-10 py-10 text-16 leading-16 font-semibold text-black-redx shadow-sm ring-1 ring-inset ring-stroke-redx hover:bg-gray-50">
                      Sort by
                      <Icon
                        icon="bi:sort-down"
                        className="ml-6 h-19 w-19 text-black-redx"
                      />
                    </MenuButton>
                  </div>

                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2 w-233 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortBys.map((sortBy, index) => (
                          <MenuItem key={index}>
                            {({ focus }) => (
                              <button
                                disabled={allProjects.length <= 0}
                                onClick={() => handlerSortBy(sortBy.name)}
                                className={classNames(
                                  "w-full text-left",
                                  focus
                                    ? "bg-gray-100 text-black-redx"
                                    : "text-black-redx",
                                  "block px-10 py-5 text-12",
                                  selectedSortBy === sortBy.name
                                    ? "font-semibold"
                                    : "font-normal",
                                )}
                              >
                                {sortBy.name}
                              </button>
                            )}
                          </MenuItem>
                        ))}
                      </div>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          {/* List */}
          <div className="flex flex-col gap-30 items-center">
            {allProjects.map((project, index) => (
              <ProjectCard
                key={index}
                initialUsers={initialUsers}
                auth={{
                  user: auth,
                }}
                project={project}
              />
            ))}
          </div>
          {/* Empty List */}
          {allProjects.length <= 0 && !isLoading && (
            <div className="w-1/2 mx-auto">
              <div className="flex flex-col justify-center items-center">
                <img
                  src="assets/img/Empty-amico 1.png"
                  alt="Empty-amico 1.png"
                  className="w-[400px] h-[400px]"
                />
                <div className="flex flex-col gap-20 justify-items-center items-center">
                  <h2 className="text-black-redx text-32 leading-[36px] font-semibold">
                    Project not found...
                  </h2>
                  <p className="text-grey-redx text-center text-16 leading-20 font-normal">
                    It looks like you haven't created a project yet.
                    <br />
                    Click ‘Create Project’ to start a new one or contact your
                    teammates to invite you.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateFormModal
        isOpen={isOpen}
        initialUsers={initialUsers}
        onClose={closeModal}
        auth={{
          user: auth,
        }}
        appUrl={baseUrl}
      />
      <NoAccessModal
        isOpen={isOpenNoAccess}
        message={projectMessage as string}
        onClose={closeModalNoAccess}
      />
    </AuthenticatedLayout>
  );
}
