import GuestLayout from "@/Layouts/GuestLayout";
import InputError from "@/Components/InputError";
import PrimaryButton from "@/Components/PrimaryButton";
import TextInput from "@/Components/TextInput";
import { useForm, SubmitHandler } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { fetcher } from "@/Services/axios";
import toast from "react-hot-toast";

type FormData = {
  email: string;
};

export default function ForgotPassword({ status }: { status?: string }) {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>();

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    try {
      // Use Inertia's post method for form submission
      await fetcher.post("/forgot-password", data);
    } catch (error) {
      toast.error("Error forgot password!");
      throw error;
    }
  };

  return (
    <GuestLayout>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      <div className="mb-4 text-sm text-gray-600">
        Forgot your password? No problem. Just let us know your email address
        and we will email you a password reset link that will allow you to
        choose a new one.
      </div>

      {status && (
        <div className="mb-4 font-medium text-sm text-green-600">{status}</div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id="email"
          type="email"
          className="mt-1 block w-full"
          {...register("email", { required: "Email is required" })}
        />

        <InputError message={errors.email?.message} className="mt-2" />

        <div className="flex items-center justify-end mt-4">
          <PrimaryButton className="ms-4" disabled={isSubmitting}>
            Email Password Reset Link
          </PrimaryButton>
        </div>
      </form>
    </GuestLayout>
  );
}
