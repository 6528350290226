import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  RefObject,
} from "react";
import { ProjectContext } from "@/Context/ProjectContext";

const Breadcrumb: React.FC<{
  list: string[];
  index: number;
  onBreadCrumbClick: (index: number) => void;
  sectionRefs: RefObject<HTMLDivElement>[];
  sectionContainerRef: RefObject<HTMLDivElement>;
}> = ({ list, index, onBreadCrumbClick }) => {
  const [activeSection, setActiveSection] = useState(list[index]);
  const [state] = useContext(ProjectContext);
  const routeMap: { [key: number]: string } = {
    0: "is_product_information_complete",
    1: "is_market_information_complete",
    2: "is_audience_information_complete",
    3: "is_competitor_information_complete",
    4: "is_objective_information_complete",
  };

  useEffect(() => {
    setActiveSection(list[index]);
  }, [index]);

  return (
    <nav className="flex justify-between pb-24" aria-label="Breadcrumb">
      <ol className="inline-flex items-center mb-3 sm:mb-0 text-12 font-normal text-grey-redx gap-x-8">
        {list.map((section, sectionIndex) => {
          const routeName = routeMap[sectionIndex];
          const routePrevious =
            sectionIndex - 1 >= 0 ? routeMap[sectionIndex - 1] : false;
          const previousStatus = routePrevious
            ? state.submission[routePrevious as keyof typeof state.submission]
            : false;
          return (
            <Fragment key={sectionIndex}>
              <li>
                <div
                  className={`flex items-center ${
                    activeSection === section
                      ? "text-blue-redx font-semibold"
                      : sectionIndex > index
                        ? "text-grey-redx"
                        : "text-blue-redx"
                  } ${
                    state.submission[
                      routeName as keyof typeof state.submission
                    ] ||
                    activeSection === section ||
                    previousStatus
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }`}
                  onClick={
                    state.submission[
                      routeName as keyof typeof state.submission
                    ] ||
                    activeSection === section ||
                    previousStatus
                      ? () => onBreadCrumbClick(sectionIndex)
                      : () => {}
                  }
                >
                  {section}
                </div>
              </li>
              {sectionIndex !== list.length - 1 && <span>/</span>}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
